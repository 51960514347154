import { ChangeEvent, Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react'
import { MergeExclusive } from 'type-fest'
import { FieldValues, Path, useFormContext } from 'react-hook-form'
import { usePrevious, useUpdateEffect } from 'react-use'

import { Icon, TextArea, TextAreaProps, TextInput, TextInputProps, Tooltip } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { convertDynamicTemplateToReadableString } from './dynamic-text-field-utils'

type ComputedTextFieldProps = {
  valueKey: string
  templateKey: string
} & MergeExclusive<
  Omit<TextAreaProps, 'value' | 'defaultValue'> & { as: 'textarea' },
  Omit<TextInputProps, 'value' | 'defaultValue'> & { as: 'input' }
>

export const DynamicTextField = memo(
  ({ readOnly, valueKey, templateKey, as = 'input', ...props }: ComputedTextFieldProps) => {
    const { t } = useLanguage('customFields', { keyPrefix: 'edit' })

    const [displayValue, _, handlers, { readableVariables }] = useDynamicTextFieldFormHelper({
      valueKey,
      templateKey,
      readOnly
    })

    return as === 'input' ? (
      <TextInput
        endComponent={
          <Tooltip content={t('variablesReferenced', { variables: readableVariables })}>
            <Icon icon="code" color="text-light" size="medium" />
          </Tooltip>
        }
        value={displayValue || ''}
        {...(props as TextInputProps)}
        {...handlers}
      />
    ) : (
      <TextArea
        icon="code"
        iconSize="medium"
        iconPlain
        tooltipText={t('variablesReferenced', { variables: readableVariables })}
        value={displayValue || ''}
        {...(props as TextAreaProps)}
        {...handlers}
      />
    )
  }
)

export function useDynamicTextFieldFormHelper<TFieldValues extends FieldValues>({
  valueKey,
  templateKey,
  readOnly
}: {
  valueKey: Path<TFieldValues>
  templateKey: Path<TFieldValues>
  readOnly?: boolean
}) {
  const { getFieldState, getValues, setValue, watch, resetField, formState } = useFormContext<TFieldValues>()
  const [displayValue, setDisplayValue] = useState<string>(getValues(valueKey) as string)
  const templateValue = watch(templateKey)
  const { isDirty: currentFormIsDirty } = formState
  const prevFormIsDirty = usePrevious(currentFormIsDirty)

  /* --------------------------------- Effects -------------------------------- */
  useUpdateEffect(() => {
    if (!currentFormIsDirty && prevFormIsDirty) {
      resetField(valueKey)
      resetField(templateKey)
      setDisplayValue(getValues(valueKey))
    }
  }, [prevFormIsDirty, currentFormIsDirty])

  /* ----------------------------- Computed Values ---------------------------- */
  const hasDynamicValue = useMemo(() => {
    return templateValue !== undefined && templateValue !== null
  }, [templateValue])

  const readableVariables = useMemo(() => {
    if (!hasDynamicValue) return
    return convertDynamicTemplateToReadableString(getValues(templateKey))
  }, [hasDynamicValue, templateValue])

  /* -------------------------------- Handlers -------------------------------- */
  const onFocus = useCallback(() => {
    if (!hasDynamicValue) return

    if (!getFieldState(valueKey).isDirty && !readOnly) setDisplayValue(getValues(templateKey))
  }, [getFieldState, setDisplayValue, getValues, valueKey, readOnly, templateKey, hasDynamicValue])

  const onBlur = useCallback(() => {
    if (!hasDynamicValue) return

    if (!getFieldState(valueKey).isDirty) setDisplayValue(getValues(valueKey))
  }, [hasDynamicValue, getFieldState, valueKey, setDisplayValue, getValues])

  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!hasDynamicValue) return

      const { value } = e.target
      setDisplayValue(value)
      // @ts-ignore there are limitations of what this component knows about the form here to make it generic, but assigning a string is expected
      setValue(templateKey, value, { shouldDirty: true })
      // @ts-ignore there are limitations of what this component knows about the form here to make it generic, but assigning a string is expected
      setValue(valueKey, value, { shouldDirty: true })
    },
    [setValue, setDisplayValue, templateKey, valueKey, hasDynamicValue]
  )

  return [displayValue, setDisplayValue, { onFocus, onBlur, onChange }, { readableVariables }] as [
    string,
    Dispatch<SetStateAction<string>>,
    {
      onFocus: typeof onFocus
      onBlur: typeof onBlur
      onChange: typeof onChange
    },
    { readableVariables: string }
  ]
}
