import { Simplify } from 'type-fest'

import { CollectionModelInterfaceBase, CollectionModelInterfaceWithActions as WithActions } from './types'
import { RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import {
  useFilteredIdsWhereTasksAndNoRolesCallback,
  useGetAllRunbookUsers,
  useGetAllRunbookUsersCallback,
  useGetRunbookUser,
  useGetRunbookUserCallback,
  useGetRunbookUsersLookup,
  useGetRunbookUsersLookupCallback,
  useOnRunbookUserAction
} from '../hooks'

export type RunbookUserModelType = Simplify<
  CollectionModelInterfaceBase<RunbookVersionUser> &
    Omit<WithActions<'RunbookPerson'>, 'usePermission' | 'usePermissionCallback'>
> & {
  useFilteredIdsWhereTasksAndNoRolesCallback: () => (
    ids: number[],
    ignoreWhere?: { streamId?: number; teamId?: number }
  ) => Promise<number[]>
}

export const RunbookUserModel: RunbookUserModelType = {
  useGet: useGetRunbookUser,
  useGetCallback: useGetRunbookUserCallback,
  useGetAll: useGetAllRunbookUsers,
  useGetAllCallback: useGetAllRunbookUsersCallback,
  useGetLookup: useGetRunbookUsersLookup,
  useGetLookupCallback: useGetRunbookUsersLookupCallback,
  useOnAction: useOnRunbookUserAction,
  useFilteredIdsWhereTasksAndNoRolesCallback: useFilteredIdsWhereTasksAndNoRolesCallback
}
