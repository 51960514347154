import { RequireExactlyOne, Simplify } from 'type-fest'

import { RunbookTeam } from 'main/services/queries/types'
import {
  useAllTeamsValue,
  useAllTeamsValueCallback,
  useCanRunbookteam,
  useGetAllTeamsBy,
  useGetAllTeamsByCallback,
  useOnRunbookTeamAction,
  useRunbookTeamsLookup,
  useRunbookTeamsLookupCallback,
  useTeamValue,
  useTeamValueCallback
} from '../hooks'
import {
  CollectionModelInterfaceBase,
  ModelCollectionAccessScope,
  ModelMultiAccessBy,
  CollectionModelInterfaceWithActions as WithActions,
  CollectionModelInterfaceWithCollectionAccessBy as WithCollectionAccessBy,
  CollectionModelInterfaceWithPermissions as WithPermissions
} from './types'
import { RunbookTeamsPermissionsResponse } from 'main/services/queries/use-runbook-versions'

type RunbookTeamCollectionAccessScope = ModelCollectionAccessScope<null>
type RunbookTeamMultiAccessBy = ModelMultiAccessBy<RequireExactlyOne<{ taskId: number; userId: number }>>

export type RunbookTeamModelType = Simplify<
  CollectionModelInterfaceBase<RunbookTeam, RunbookTeamCollectionAccessScope> &
    WithPermissions<RunbookTeamsPermissionsResponse> &
    WithCollectionAccessBy<RunbookTeam, RunbookTeamMultiAccessBy> &
    Omit<WithActions<'RunbookTeam'>, 'usePermission' | 'usePermissionCallback'>
>

export const RunbookTeamModel: RunbookTeamModelType = {
  useGet: useTeamValue,
  useGetCallback: useTeamValueCallback,

  useGetAll: useAllTeamsValue,
  useGetAllCallback: useAllTeamsValueCallback,

  useGetAllBy: useGetAllTeamsBy,
  useGetAllByCallback: useGetAllTeamsByCallback,

  useGetLookup: useRunbookTeamsLookup,
  useGetLookupCallback: useRunbookTeamsLookupCallback,

  useCan: useCanRunbookteam,

  useOnAction: useOnRunbookTeamAction
}
