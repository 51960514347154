/** @description Returns a string that is a list of all the dynamic variables in the input string
 * @param {string} input - The string to convert
 * @example convertDynamicTemplateToReadableString('{{Runbook.name}} {{Runbook.description}}')
 * @returns 'Runbook.name, Runbook.description'
 */
export const convertDynamicTemplateToReadableString = (input: string = '') => {
  const regex = /{{(.*?)}}/g
  let matches: string[] = []
  let match: RegExpExecArray | null

  while ((match = regex.exec(input)) !== null) {
    matches.push(match[1].trim())
  }

  return matches.join(', ')
}

/** @description Matches on any string that has a {{variable}} in it
 * @param {string} input - The string to check for dynamic variables
 */
export const hasDynamicTemplate = (input: string = '') => {
  const regex = /{{(.*?)}}/g
  return regex.test(input)
}
