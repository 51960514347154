import { useCallback, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { useFormContext } from 'react-hook-form'
import { useMount } from 'react-use'
import { pick } from 'lodash'

import { Box, RadioboxGroupProps, TeamListItem, Text, UserListItem } from '@cutover/react-ui'
import { FormModal, RadioboxGroupField, SelectField } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { RunbookTeamModel, RunbookUserModel, StreamModel } from 'main/data-access'
import { RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import { RunbookTeam } from 'main/services/queries/types'

const validationSchema = yup.object().shape({
  selected: yup.string().oneOf(['none', 'user', 'team']).required(),
  user_id: yup.number().when('selected', {
    is: 'user',
    then: schema => schema.required(),
    otherwise: schema => schema.notRequired()
  }),
  runbook_team_id: yup.number().when('selected', {
    is: 'team',
    then: schema => schema.required(),
    otherwise: schema => schema.notRequired()
  }),
  users_to_reassign: yup.array().of(yup.number())
})

type DeleteMode = 'team' | 'user'
export type DeleteUserTeamForm = yup.InferType<typeof validationSchema>
export type DeleteUserTeamModalProps = {
  ids: number[]
  mode: DeleteMode
  onSubmit?: (form: DeleteUserTeamForm) => void
  onClose?: () => void
}

export const DeleteAndReassignUserTeamModal = ({ onSubmit, ids, mode, onClose }: DeleteUserTeamModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'peoplePanel' })
  const [userOptions, setUserOptions] = useState<{ label: string; value: number }[] | undefined>(undefined)
  const getStreamsBy = StreamModel.useGetAllByCallback()
  const getTeamsBy = RunbookTeamModel.useGetAllByCallback()
  const teams = RunbookTeamModel.useGetAll()
  const users = RunbookUserModel.useGetAll()
  const runbookTeamLookup = RunbookTeamModel.useGetLookup()
  const runbookUserLookup = RunbookUserModel.useGetLookup()
  const lookup = mode === 'team' ? runbookTeamLookup : runbookUserLookup

  useMount(() => {
    calculateAndSetUserOptions() // we have to do this here because it's async
  })

  const calculateAndSetUserOptions = async () => {
    if (mode === 'user') {
      const opts = users.map(t => ({ label: t.name, value: t.id }))
      return setUserOptions(opts.filter(o => !ids.includes(o.value)))
    }

    // if in team mode we remove the option to reassign to users that aren't in any other teams or streams
    // because otherwise the team + its users will be deleted and we don't want to orphan users on tasks
    const userOptions = await getUserOptionsWhereNoTeamsOrStreams(users, { teamIds: ids })
    setUserOptions(userOptions)
  }

  // we remove users in team mode where they don't have any other roles/streams on the runbook
  // this needs to happen otherwise if you have a 'team member' user assigned to a task directly
  // (eg. not through the team) then they can become orphaned if not specified in the payload
  const getUserOptionsWhereNoTeamsOrStreams = useCallback(
    async (users: RunbookVersionUser[], ignore: { teamIds?: number[] } = {}) => {
      return await users.reduce(async (accPromise, user) => {
        const acc = await accPromise

        if (await userHasOtherRolesOrTeams(user, ignore)) return [...acc, { value: user.id, label: user.name }]

        return acc
      }, Promise.resolve([] as { label: string; value: number }[]))
    },
    [getTeamsBy, getStreamsBy]
  )

  const userHasOtherRolesOrTeams = async (user: RunbookVersionUser, ignore: { teamIds?: number[] } = {}) => {
    if (user.is_admin) return true

    const teams = (await getTeamsBy({ userId: user.id })).filter(t => !ignore.teamIds?.includes(t.id))
    const streams = await getStreamsBy({ userId: user.id })
    return teams.length > 0 || streams.length > 0
  }

  const teamOptions = useMemo(() => {
    const opts = teams.map(t => ({ label: t.name, value: t.id }))
    return mode === 'team' ? opts.filter(o => !ids.includes(o.value)) : opts
  }, [teams, ids, mode])

  const getUserIdsToReassignFromTeams = async () => {
    if (mode === 'user') return ids

    const teams = pick(runbookTeamLookup, ids)
    const allUserIds = new Set(Object.values(teams).flatMap(t => t.user_ids))
    const usersToReassign = await Promise.all(
      [...allUserIds].map(
        async id => (!(await userHasOtherRolesOrTeams(runbookUserLookup[id], { teamIds: ids })) ? id : false) // Return the id if it meets the condition, otherwise return null
      )
    )

    return usersToReassign.filter(u => u !== false) as number[]
  }

  const handleSubmit = async (form: DeleteUserTeamForm) => {
    form.users_to_reassign = await getUserIdsToReassignFromTeams()
    onSubmit?.(form)
  }

  return (
    <FormModal<DeleteUserTeamForm>
      onSubmit={handleSubmit}
      onClose={onClose}
      schema={validationSchema}
      defaultValues={{ selected: 'none', users_to_reassign: [] }}
      open
      title={t('deleteModal.title', { context: mode, count: ids.length })}
      description={t('deleteModal.description', {
        context: mode,
        count: ids.length
      })}
      confirmText={t('deleteModal.submit')}
      confirmIcon="save"
    >
      <DeleteUserTeamModalForm
        mode={mode}
        ids={ids}
        teamOptions={teamOptions}
        userOptions={userOptions}
        lookup={lookup}
      />
    </FormModal>
  )
}

const DeleteUserTeamModalForm = ({
  ids,
  mode,
  teamOptions = [],
  userOptions = [],
  lookup
}: Omit<DeleteUserTeamModalProps, 'onSubmit'> & {
  teamOptions?: { label: string; value: number }[]
  userOptions?: { label: string; value: number }[]
  lookup: Record<number, RunbookTeam | RunbookVersionUser>
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'peoplePanel' })
  const { watch, setValue } = useFormContext<DeleteUserTeamForm>()

  const reassignOption = watch('selected')
  const reassignOptions = useMemo<RadioboxGroupProps['options']>(
    () => [
      { value: 'none', label: t('deleteModal.reassignOption.none') },
      { value: 'team', label: t('deleteModal.reassignOption.team'), disabled: teamOptions.length === 0 },
      {
        value: 'user',
        label: t('deleteModal.reassignOption.user'),
        disabled: !userOptions || userOptions?.length === 0
      }
    ],
    [userOptions, teamOptions]
  )

  useEffect(() => {
    setValue('user_id', undefined)
    setValue('runbook_team_id', undefined)
  }, [reassignOption])

  return (
    <Box gap="medium">
      <Box>
        {ids.map(id => {
          const object = lookup[id]
          if (!object) return null

          if (mode === 'team') {
            const team = object as RunbookTeam
            return (
              <TeamListItem
                key={team.id}
                name={team.name}
                id={team.id}
                color={team.color}
                usersCount={team.users_count}
                size="small"
                draggable={false}
              />
            )
          }
          if (mode === 'user') {
            const user = object as RunbookVersionUser
            return (
              <UserListItem
                color={user.color}
                firstName={user.first_name}
                handle={user.handle}
                id={user.id}
                key={user.id}
                lastName={user.last_name}
                name={user.name}
                online={user.online}
                size="small"
                draggable={false}
                readOnly
              />
            )
          }
        })}
      </Box>
      <Text>{t('deleteModal.instruction')}</Text>
      <Box>
        <RadioboxGroupField<DeleteUserTeamForm>
          name="selected"
          label={t('deleteModal.reassignOption.label')}
          direction="row"
          options={reassignOptions}
        />
        {reassignOption === 'team' && (
          <SelectField<DeleteUserTeamForm>
            placeholder={t('deleteModal.reassignOption.selectPlaceholder', { context: 'team' })}
            name="runbook_team_id"
            options={teamOptions}
          />
        )}
        {reassignOption === 'user' && (
          <SelectField<DeleteUserTeamForm>
            loading={!userOptions}
            placeholder={t('deleteModal.reassignOption.selectPlaceholder', { context: 'user' })}
            name="user_id"
            options={userOptions}
          />
        )}
      </Box>
    </Box>
  )
}
