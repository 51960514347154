import { unescape } from 'lodash'
import { MutationOptions, useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { apiClient_UNSTABLE, ApiError } from '../api'
import {
  RunbookStreamCreateResponse,
  RunbookStreamDestroyResponse,
  RunbookStreamShowResponse,
  RunbookStreamUpdateResponse
} from '../api/data-providers/runbook-types'
import { RagStatus, Role } from './types'

/* ------------------------------- Get Stream ------------------------------- */

type StreamGetParams = {
  runbookId: number | string
  runbookVersionId: number | string
  streamId: number | string
}

export const getStream = async ({ runbookId, runbookVersionId, streamId }: StreamGetParams) => {
  const { data } = await apiClient_UNSTABLE.get<RunbookStreamShowResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/streams/${streamId}`
  )

  data.stream.name = unescape(data.stream.name)

  return data
}

export const useGetStream = (params: StreamGetParams, options?: UseQueryOptions<RunbookStreamShowResponse, ApiError>) =>
  useQuery<RunbookStreamShowResponse, ApiError>(
    ['stream', params.streamId],
    async () => await getStream(params),
    options
  )

/* ------------------------------ Create Stream ----------------------------- */

type StreamCreateProps = {
  runbookId: number | string
  runbookVersionId: number | string
}

export type StreamCreatePayload = {
  stream: {
    name: string
    parent_id: string | null
  }
}

export const createStream = async ({
  runbookId,
  runbookVersionId,
  ...payload
}: StreamCreateProps & StreamCreatePayload) => {
  const { data } = await apiClient_UNSTABLE.post<RunbookStreamCreateResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/streams`,
    payload
  )

  return data
}

/* ------------------------------ Update Stream ----------------------------- */

type StreamUpdateParams = {
  runbookId: number
  runbookVersionId: number
  streamId: number
}

export type StreamUpdatePayload = {
  reassign?: {
    runbook_team_id?: number
    selected?: string
    user_id?: number
    users_to_reassign?: number[]
  }
  stream: {
    // color: string | null // TODO
    default_task_type_id?: number | null
    description?: string | null
    name: string
    roles: Role[]
    status: RagStatus
    status_message?: string | null
  }
  users_to_remove_from_runbook?: number[]
}

export const updateStream = async ({
  runbookId,
  runbookVersionId,
  streamId,
  ...payload
}: StreamUpdateParams & StreamUpdatePayload) => {
  const { data } = await apiClient_UNSTABLE.put<RunbookStreamUpdateResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/streams/${streamId}`,
    payload
  )
  // TODO: look into this.processUsers (role_types.model.js) - here and elswhere
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return data!
}

export const useUpdateStream = (
  options: MutationOptions<RunbookStreamUpdateResponse, ApiError, StreamUpdatePayload & StreamUpdateParams> = {}
) => {
  const queryClient = useQueryClient()

  return useMutation<RunbookStreamUpdateResponse, ApiError, StreamUpdatePayload & StreamUpdateParams>(
    ['stream-update'],
    updateStream,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<RunbookStreamShowResponse | undefined>(['stream', data.stream.id], prevData => {
          if (!prevData) return

          return {
            ...prevData,
            stream: data.stream
          }
        })
        options?.onSuccess?.(data, variables, context)
      }
    }
  )
}

/* ------------------------------ Delete Stream ----------------------------- */

type StreamDeleteProps = {
  runbookId: number
  runbookVersionId: number
  streamId: number
}

export type StreamDeletePayload = {
  new_stream_id?: number | null
}

export const deleteStream = async ({
  runbookId,
  runbookVersionId,
  streamId,
  ...payload
}: StreamDeleteProps & StreamDeletePayload) => {
  const { data } = await apiClient_UNSTABLE.patch<RunbookStreamDestroyResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/streams/${streamId}`,
    payload
  )
  return data
}
