import { useCallback } from 'react'
import { useRecoilCallback, useRecoilTransaction_UNSTABLE, useRecoilValue } from 'recoil'
import { produce } from 'immer'

import { RunbookUserModelType } from 'main/data-access/models/runbook-user-model'
import { runbookVersionResponseState_INTERNAL, usersLookupState, usersState, userState } from 'main/recoil/runbook'
import {
  RunbookPersonCreateResponse,
  RunbookPersonDeleteResponse,
  RunbookResponse
} from 'main/services/api/data-providers/runbook-types'
import { addUsersOrTeams, updateAllChangedTasks } from './shared-updates'
import { useGetAllTeamsByCallback } from './runbook-team'
import { useTaskIdsByCallback } from './task'
import { useGetAllStreamsByCallback } from './stream'

export const useGetRunbookUser: RunbookUserModelType['useGet'] = (id: number) => {
  return useRecoilValue(userState({ id }))
}

export const useGetRunbookUserCallback: RunbookUserModelType['useGetCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async (id: number) => {
    return await snapshot.getPromise(userState({ id }))
  })
}

export const useGetAllRunbookUsers: RunbookUserModelType['useGetAll'] = () => {
  return useRecoilValue(usersState)
}

export const useGetAllRunbookUsersCallback: RunbookUserModelType['useGetAllCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    return await snapshot.getPromise(usersState)
  })
}

export const useGetRunbookUsersLookup: RunbookUserModelType['useGetLookup'] = () => {
  return useRecoilValue(usersLookupState)
}

export const useGetRunbookUsersLookupCallback: RunbookUserModelType['useGetLookupCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    return await snapshot.getPromise(usersLookupState)
  })
}

export const useFilteredIdsWhereTasksAndNoRolesCallback: RunbookUserModelType['useFilteredIdsWhereTasksAndNoRolesCallback'] =
  () => {
    const getUserLookup = useGetRunbookUsersLookupCallback()
    const getRunbookTeamsBy = useGetAllTeamsByCallback()
    const getTaskIdsBy = useTaskIdsByCallback()
    const getStreamsBy = useGetAllStreamsByCallback()

    return useRecoilCallback(
      () => async (ids, ignoreWhere) => {
        const userLookup = await getUserLookup()

        return await ids.reduce(async (accPromise, userId) => {
          const acc = await accPromise
          const user = userLookup[userId]
          if (user.is_admin) return acc

          const teams = await getRunbookTeamsBy({ userId: user.id })
          const filteredTeams = ignoreWhere?.teamId ? teams.filter(t => t.id !== ignoreWhere.teamId) : teams

          const taskIds = await getTaskIdsBy({ user_id: user.id })

          const streams = await getStreamsBy({ userId: user.id })
          const filteredStreams = ignoreWhere?.streamId ? streams.filter(s => s.id !== ignoreWhere.streamId) : streams

          if (taskIds.length > 0 && filteredTeams.length === 0 && filteredStreams.length === 0) return [...acc, user.id]
          return acc
        }, Promise.resolve([] as number[]))
      },
      [getUserLookup, getRunbookTeamsBy, getTaskIdsBy, getStreamsBy]
    )
  }

export const useOnRunbookUserAction: RunbookUserModelType['useOnAction'] = () => {
  const processRunbookPersonCreateResponse = useProcessRunbookPersonCreateResponse()
  const processRunbookPersonDeleteResponse = useProcessRunbookPersonDeleteResponse()

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'create':
          processRunbookPersonCreateResponse(response as RunbookPersonCreateResponse)
          break
        case 'delete':
          processRunbookPersonDeleteResponse(response as RunbookPersonDeleteResponse)
        default:
          return
      }
    },
    [processRunbookPersonCreateResponse, processRunbookPersonDeleteResponse]
  )
}

const useProcessRunbookPersonCreateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookPersonCreateResponse) => {
    addUsersOrTeams(transactionInterface)({ users: response.users, teams: response.runbook_teams })
  })
}

export const useProcessRunbookPersonDeleteResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookPersonDeleteResponse) => {
    const { set } = transactionInterface

    updateAllChangedTasks(transactionInterface)(response.meta.changed_tasks)

    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        const { removed_user_ids } = response.meta
        removed_user_ids.forEach(id => {
          const index = draftRunbookVersionResponse.meta.users.findIndex(user => user.id === id)
          draftRunbookVersionResponse.meta.users.splice(index, 1)
        })
      })
    )
  })
}
