import { keyBy } from 'lodash'
import { selector, selectorFamily } from 'recoil'

import { runbookVersionMetaState } from './runbook-version'
import {} from 'main/services/queries/use-runbook-versions'
import { RunbookTeam } from 'main/services/queries/types'
import { taskListState } from '../tasks/task-list'

export const teamsState = selector<RunbookTeam[]>({
  key: 'teams:list',
  get: ({ get }) => {
    return get(runbookVersionMetaState).runbook_teams
  }
})

export const teamsStateLookup = selector<Record<RunbookTeam['id'], RunbookTeam>>({
  key: 'teams:lookup',
  get: ({ get }) => {
    return keyBy(get(teamsState), 'id')
  }
})

export const teamState = selectorFamily<RunbookTeam, number>({
  key: 'teams:id',
  get:
    id =>
    ({ get }) =>
      get(teamsStateLookup)[id]
})

export const teamTaskIdsRecordState = selector<Record<number, number[]>>({
  key: 'team:tasks:ids',
  get: ({ get }) => {
    const allTasks = get(taskListState)

    return allTasks.reduce<Record<number, number[]>>((acc, task) => {
      task.runbook_team_ids.forEach(teamId => {
        if (!acc[teamId]) {
          acc[teamId] = []
        }

        acc[teamId].push(task.id)
      })

      return acc
    }, {} as Record<number, number[]>)
  }
})
