import { forwardRef, useEffect } from 'react'
import { eventManager } from 'event-manager'
import { UseFormReturn } from 'react-hook-form'
import { UseMutationResult } from 'react-query'
import styled from 'styled-components'

import { useNotify } from '@cutover/react-ui'
import { TeamFormItem } from './team-form-item'
import { ErrorItem, FormValues, RunbookPeoplePayload, SelectedUserTeam } from './types'
import { UserFormItem } from './user-form-item'
import { Stream, ValidationRunbookTeam } from '../../right-panels/people-panel/types'
import { useLanguage } from 'main/services/hooks'
import { useIsOnReactRunbook } from 'main/services/routing'
import { RunbookPersonCreateResponse } from 'main/services/api/data-providers/runbook-types'
import { RunbookUserModel } from 'main/data-access'

export type SelectedUsersTeamsFormProps = {
  closeModal: () => void
  removeItem: (index: number) => void
  items: SelectedUserTeam[]
  methods: UseFormReturn<FormValues>
  runbookTeams: ValidationRunbookTeam[]
  setRunbookTeams: (array: ValidationRunbookTeam[]) => void
  setErrorList: (errorList: ErrorItem[]) => void
  errorList: ErrorItem[]
  removeErrorItem: (id: number, type: 'user' | 'team') => void
  streams: Stream[]
  mutation: UseMutationResult<RunbookPersonCreateResponse, unknown, RunbookPeoplePayload, unknown>
  templateType?: string
  newCustomTeamIndex: number | null
  setDisableModalFocusLock?: (shouldDisable: boolean) => void
  bypassNotification?: boolean
}

export const SelectedUsersTeamsForm = forwardRef<HTMLUListElement, SelectedUsersTeamsFormProps>(
  (
    {
      closeModal,
      removeItem,
      methods,
      items,
      runbookTeams,
      setRunbookTeams,
      setErrorList,
      errorList,
      removeErrorItem,
      streams,
      mutation,
      templateType,
      newCustomTeamIndex,
      setDisableModalFocusLock,
      bypassNotification = false
    },
    ref
  ) => {
    const { t } = useLanguage('runbook', { keyPrefix: 'addUsersTeams' })
    const notify = useNotify()
    const processRunbookPersonCreateResponse = RunbookUserModel.useOnAction('create')
    const isOnReactRunbook = useIsOnReactRunbook()

    useEffect(() => {
      if (mutation.isSuccess) {
        // update runbook teams for frontend validation
        if (mutation.variables?.runbook_teams) {
          setRunbookTeams(
            runbookTeams.concat(
              mutation.variables.runbook_teams.map(
                ({ user_ids: _userIds, team_id: _teamId, type: _type, ...item }) => item
              )
            )
          )
        }

        if (mutation.data && isOnReactRunbook) {
          processRunbookPersonCreateResponse(mutation.data)
        }

        eventManager.emit('runbook-teams-users-added')

        if (!bypassNotification) {
          notify.success(t('notification.success.notification'), { title: t('notification.success.title') })
        }

        closeModal()
      }
    }, [mutation.isSuccess])

    useEffect(() => {
      if (mutation.isError) {
        const errorObject = mutation.error as { errors?: string[] }
        const errors = errorObject?.errors as string[]

        if (errors?.length > 0) {
          const errorList: ErrorItem[] = [
            {
              message: errors[0],
              source: 'backend'
            }
          ]
          setErrorList(errorList)
        }
      }
    }, [mutation.isError])

    return (
      <form onSubmit={e => e.preventDefault()}>
        <SelectedItems data-testid="selected-users-teams-list" ref={ref}>
          {items.map((item, index) =>
            item.type === 'user' ? (
              <UserFormItem
                key={item.key}
                item={item}
                methods={methods}
                removeItem={removeItem}
                index={index}
                errorList={errorList}
                setErrorList={setErrorList}
                streams={streams}
                templateType={templateType}
                setDisableModalFocusLock={setDisableModalFocusLock}
              />
            ) : (
              <TeamFormItem
                key={item.key}
                item={item}
                methods={methods}
                removeItem={removeItem}
                index={index}
                selectedItems={items}
                runbookTeams={runbookTeams}
                errorList={errorList}
                removeErrorItem={removeErrorItem}
                newCustomTeamIndex={newCustomTeamIndex}
                setDisableModalFocusLock={setDisableModalFocusLock}
              />
            )
          )}
        </SelectedItems>
      </form>
    )
  }
)

const SelectedItems = styled.ul`
  padding-top: 8px;
  list-style: none;
`
