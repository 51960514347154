import { memo, Ref, SyntheticEvent, useCallback } from 'react'

import { AvatarSubject, DependencyType, TaskItem, useNotify } from '@cutover/react-ui'
import { useDropAssignment, useRunbookComponentsStateLookup, useTaskListTaskUsers } from 'main/recoil/data-access'
import { useForceRerenderOnTimeout, useLanguage } from 'main/services/hooks'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import { TaskItemCreateToggle } from './task-item-create'
import { useTaskListItemProps } from './task-list-item-props'
import { useTaskListItemIntegrationStatus } from './use-task-list-item-integration-status'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  CommentModel,
  CurrentRunbookVersionModel,
  RunbookTeamModel,
  RunbookViewModel,
  StreamModel,
  TaskModel,
  TaskProgressionViewModel,
  TaskTypeModel
} from 'main/data-access'
import { getTask } from 'main/services/queries/use-task'

type TaskContentProps = {
  id: number
  previousTaskId?: number
  nextTaskId?: number
  isEditing?: boolean
  isFaded?: boolean
}

// TODO: remove when transaction updates have been made to recoil data updating
export const TaskListItem = memo(({ id, ...props }: TaskContentProps) => {
  const task = TaskModel.useGet(id)
  if (!task) return null
  return <TaskListItemInner id={id} {...props} />
})

const TaskListItemInner = (props: TaskContentProps) => {
  const { isEditing, id, previousTaskId, nextTaskId } = props
  const first = !previousTaskId

  const toggleActionsMenu = RunbookViewModel.useAction('taskMenu:toggle:actions')
  const toggleDependenciesMenu = RunbookViewModel.useAction('taskMenu:toggle:dependencies')
  const { open: isMenuOpen, taskId, type } = RunbookViewModel.useGet('menu')
  const { active: activeModal } = RunbookViewModel.useGet('modal')
  const isUpdating = RunbookViewModel.useGet('loadingIds')[id] || false
  const isActioning = activeModal?.type === 'task-action' ? id === activeModal.id : undefined

  const toggleSelectedIds = RunbookViewModel.useAction('selectedIds:toggle')
  const deselectAllIds = RunbookViewModel.useAction('selectedIds:removeAll')
  const selected = RunbookViewModel.useGet('selectedIds').includes(id)

  const notify = useNotify()

  const taskOverride = TaskModel.useGetOverride(id)
  const togglePeoplePanel = useToggleRightPanel(
    'user-team-details-edit',
    (openPanel, openingPanel) => openPanel.userOrTeam?.id === openingPanel.userOrTeam?.id
  )
  const toggleCommentsPanel = useToggleRightPanel('runbook-comments')
  const toggleTaskEditPanel = useToggleRightPanel('task-edit')
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const openModal = RunbookViewModel.useAction('modal:open')
  const toggleTaskCreate = RunbookViewModel.useAction('taskCreate:toggle')

  const runbookVersion = ActiveRunbookVersionModel.useGet()
  const task = TaskModel.useGet(id)
  const stream = StreamModel.useGet(task.stream_id)
  const runbookCurrentVersion = CurrentRunbookVersionModel.useGet()
  const runbook = ActiveRunbookModel.useGet()
  const userCanUpdate = ActiveRunbookModel.useCan('update')
  const taskComments = CommentModel.useGetAllBy({ taskInternalId: task.internal_id })
  const commentsCount = taskComments.length
  const parentStream = StreamModel.useGet(stream?.parent_id || 0)
  const previousTask = TaskModel.useGet(previousTaskId || id)
  const previousTaskType = TaskTypeModel.useGet(previousTask?.task_type_id)
  const runbookComponentLookup = useRunbookComponentsStateLookup()
  const teams = RunbookTeamModel.useGetAllBy({ taskId: id })
  const taskType = TaskTypeModel.useGet(task.task_type_id)
  const users = useTaskListTaskUsers(id)

  const getCreateFromTaskPermission = TaskModel.usePermissionCallbackSync('create')
  const { can: canCreateTasks } = RunbookViewModel.usePermission('create:tasks')
  const displayCreateFromIcon = canCreateTasks && (task.stage === 'default' || runbook.stage === 'paused')

  const getProgressTaskPermission = TaskModel.usePermissionCallbackSync('progress')
  const getUpdateTaskPermission = TaskModel.usePermissionCallbackSync('update')
  const getIsTaskActionablePermission = TaskModel.usePermissionCallbackSync()
  const float = TaskModel.useGetFloat(id)
  const critical = TaskModel.useGetIsCritical(id)

  const { can: canProgressTask } = getProgressTaskPermission(id)
  const { can: canUpdateTask } = getUpdateTaskPermission(id)
  const { can: taskIsActionable } = getIsTaskActionablePermission(id)

  const taskItemProps = useTaskListItemProps({
    showCreateAfter: displayCreateFromIcon,
    canUpdate: userCanUpdate,
    critical,
    commentsCountState: commentsCount,
    float,
    first,
    iconDisabled: !taskIsActionable,
    nextTaskId,
    parentStream,
    previousTask,
    previousTaskType,
    run: runbookVersion.run,
    runbookComponent: task.runbook_component_id ? runbookComponentLookup[task.runbook_component_id] : undefined,
    runbookCurrentVersion,
    runbookVersion,
    runbook,
    stream,
    task,
    taskType,
    teams,
    users
  })

  // Fixed-start icon should be changed to play-arrow if fixed start time in the past.
  const delay =
    task.start_fixed && taskItemProps.iconProps.stageIcon === 'fixed-start'
      ? task.start_fixed * 1000 - Date.now()
      : undefined

  useForceRerenderOnTimeout(delay)

  const onSkipTask = TaskModel.useAction('skip')
  const progressTask = TaskModel.useAction('progress')
  const updateFunctionalOauthUser = TaskModel.useAction('update_functional_oauth_user')
  const getNextProgressionModal = TaskProgressionViewModel.getCallback('nextModal')
  const { handleDropAssign, isLoadingAvatar, isDropPermitted } = useDropAssignment({ canUpdateTask, teams, users })

  const { integrationActionItem, integrationOptions, integrationStatusProps } = useTaskListItemIntegrationStatus(
    task
  ) ?? {
    integrationActionItem: undefined,
    integrationOptions: {},
    integrationStatusProps: undefined
  }

  const handleOptionsClick = useCallback(
    (triggerRef: Ref<HTMLElement>) => {
      toggleActionsMenu({ triggerRef, task, integrationActionItem, integrationOptions })
    },
    [toggleActionsMenu, task, integrationActionItem, integrationOptions]
  )

  const handleDependenciesClick = useCallback(
    (triggerRef: Ref<HTMLElement>, dependencyType: DependencyType) => {
      toggleDependenciesMenu({ triggerRef, dependencyType, task })
    },
    [toggleDependenciesMenu, task]
  )

  const handleClickTaskItem = useCallback(() => {
    toggleTaskEditPanel({ taskId: id })
  }, [id])

  const handleBulkTaskSelection = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      const nativeEvent = e.nativeEvent as MouseEvent
      toggleSelectedIds(id, nativeEvent.shiftKey)
    },
    [toggleSelectedIds, id]
  )

  const onAdd = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      toggleTaskCreate({ predecessor: id })
    },
    [toggleTaskCreate, id]
  )

  const clickable =
    taskItemProps.iconProps.hoverIcon === 'add' || canProgressTask || taskOverride?.type === 'fixed-start'

  const handleClickTaskIcon = useCallback(
    async (e: SyntheticEvent) => {
      e.stopPropagation()
      // We need to do this check here because we still want to show the add icon if all other checks to show it pass
      // so we can show specific messaging for this limitation vs just disabling click.
      if (displayCreateFromIcon) {
        const { can: canCreateFromHere } = getCreateFromTaskPermission(id)
        if (!canCreateFromHere) {
          // TODO: use error that is returned from getCreateFromPermission to determine this properly
          notify.warning(t('startedSuccessorWarning.message'), { title: t('startedSuccessorWarning.title') })
        } else onAdd(e)
        return
      }

      // When the integration action item uses OAuth, get the task to check for a functional oauth user
      const isOauthIntegration = integrationActionItem?.settings?.auth_type === 'OAuth'
      if (isOauthIntegration) {
        const { task } = await getTask({
          runbookId: runbookVersion.runbook_id,
          runbookVersionId: runbookVersion.id,
          taskId: id
        })
        updateFunctionalOauthUser(task)
      }

      const nextModal = await getNextProgressionModal(id)
      return nextModal ? openModal(nextModal) : progressTask(id)
    },
    [getNextProgressionModal, id, getCreateFromTaskPermission, displayCreateFromIcon]
  )

  const handleCommentsClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    toggleCommentsPanel({ taskId: id, taskInternalId: task.internal_id })
  }, [])

  const handleSkipClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      deselectAllIds()
      onSkipTask(id)
    },
    [deselectAllIds, id]
  )

  const handleClickAvatar = useCallback(
    (e: SyntheticEvent, subject?: AvatarSubject) => {
      e.stopPropagation()
      togglePeoplePanel({ userOrTeam: subject })
    },
    [togglePeoplePanel]
  )

  const handleErrorClick = useCallback(() => {}, [])

  const handleDrop = (e: React.DragEvent) => {
    if (!isDropPermitted(e)) {
      return false
    }

    handleDropAssign({ taskId: task.id, data: JSON.parse(e.dataTransfer.getData('text')) })
  }

  return (
    <>
      <TaskItem
        {...taskItemProps}
        isOptionsMenuOpen={taskId === id && isMenuOpen && type === 'options'}
        draggable={false}
        highlight={isEditing}
        isLoadingTeams={isLoadingAvatar}
        onChangeSelect={handleBulkTaskSelection}
        onClick={handleClickTaskItem}
        onClickAvatar={handleClickAvatar}
        onClickComments={handleCommentsClick}
        onClickError={handleErrorClick}
        onClickOptions={handleOptionsClick}
        onClickDependencies={handleDependenciesClick}
        onClickSkip={handleSkipClick}
        onDrop={handleDrop}
        selected={selected}
        editable={canUpdateTask}
        updating={isUpdating}
        iconProps={{
          ...taskItemProps.iconProps,
          isLoading: isUpdating || isActioning,
          onClick: clickable ? handleClickTaskIcon : undefined
        }}
        labelCommentsButton={t('comments', { count: commentsCount || task.comments_count })}
        labelEarly={t('taskStartDiffText.early')}
        labelLate={t('taskStartDiffText.late')}
        labelMenuButton={t('moreOptions')}
        labelSelectCheckbox={t('selectTask')}
        labelSkipButton={t('skipTaskButtonLabel')}
        labelStarted={taskItemProps.started ? t('taskStartDiffText.forecastToStart') : t('taskStartDiffText.started')}
        integrationStatusProps={integrationStatusProps}
        isFaded={props.isFaded}
      />
      <TaskItemCreateToggle predecessorId={id} nextTaskId={nextTaskId} />
    </>
  )
}
