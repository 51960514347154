import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Pill, Text } from '@cutover/react-ui'
import * as ListItem from './runbook-list-item-layout'
import { useRunbokListCustomFieldPillLabels } from 'main/components/workspace/pages/runbooks-list/use-custom-field-pill-labels'
import { useLanguage } from 'main/services/hooks'
import { RunbookListRunbook } from 'main/services/queries/types'

export const RunbookItemHeader = ({
  runbook,
  createLinkTo
}: {
  runbook: RunbookListRunbook
  createLinkTo?: (id: number) => string
}) => {
  const { field_values: fieldValues, timezone, template_type: templateType, meta } = runbook
  const isTemplate = templateType === 'default'
  const isSnippet = templateType === 'snippet'
  const isLinkedRunbook = !isTemplate && !isSnippet && meta?.linked_status?.parent_name
  const { customFieldPillLabels } = useRunbokListCustomFieldPillLabels({ fieldValues, timezone: timezone as string })
  const { t } = useLanguage('runbooks', { keyPrefix: 'list.listItem' })

  return (
    <ListItem.LeftItemTextTitle>
      {isLinkedRunbook && meta?.linked_status ? (
        <LinkedRunbookTitle
          parentRunbookName={meta?.linked_status?.parent_name}
          parentArchived={meta?.linked_status?.parent_archived}
          runbookName={runbook.name}
          parentId={meta?.linked_status?.parent_id}
          createLinkTo={createLinkTo}
        />
      ) : (
        <Text truncate="tip" tipPlacement="top" css="display: inline-block" weight={isTemplate ? 600 : undefined}>
          {runbook.name}
        </Text>
      )}
      {runbook.admin && <AdminIndicator />}
      {runbook.restricted && (
        <Pill
          data-testid={`restricted-pill-${runbook.id}`}
          icon="visibility-hide"
          label={t('restricted')}
          color="primary"
          css={{ marginLeft: !runbook.admin ? '6px' : '0' }}
        />
      )}
      {/* custom field pills display */}
      {Boolean(fieldValues.length && !isSnippet) && (
        <>
          {customFieldPillLabels
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((item, i) => (
              <Pill
                {...item}
                key={`${item.label}-${i}`}
                css="margin-left: 4px;"
                data-testid={`custom-field-pill-${item.label}`}
              />
            ))}
        </>
      )}
    </ListItem.LeftItemTextTitle>
  )
}

const LinkedRunbookTitle = ({
  parentRunbookName,
  parentArchived,
  runbookName,
  parentId,
  createLinkTo
}: {
  parentRunbookName: string
  parentArchived: boolean
  runbookName: string
  parentId: number
  createLinkTo?: (id: number) => string
}) => {
  const navigate = useNavigate()
  const { t } = useLanguage('runbooks')
  const archived = t('list.listItem.archived')

  const parentLink = parentArchived ? (
    <Text
      truncate="tip"
      tipPlacement="top"
      color="text-light"
      css="max-width: 155px;display: inline-block;"
      alignSelf="center"
    >
      {parentRunbookName} ({archived})
    </Text>
  ) : !parentId ? (
    <Text truncate="tip" tipPlacement="top" css="display: inline-block">
      {parentRunbookName}
    </Text>
  ) : (
    <Text
      data-testid="linked-runbook-parent-link"
      color="primary"
      css="text-decoration: underline; display: inline-block; max-width: 155px;"
      truncate="tip"
      tipPlacement="top"
      alignSelf="center"
      onClick={(e: any) => {
        e.preventDefault()
        e.stopPropagation()
        navigate(createLinkTo?.(parentId) ?? '')
      }}
    >
      {parentRunbookName}
    </Text>
  )

  return (
    <>
      {parentLink}
      <Text truncate tip={runbookName} tipPlacement="top">{` / ${runbookName}`}</Text>
    </>
  )
}

const AdminIndicator = () => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'list.listItem' })

  return (
    <AdminTextContainer>
      <ShortText size="16px" weight={300} color="text-disabled">
        [<span>{t('admin.short')}</span>]
      </ShortText>
      <LongText size="16px" weight={300} color="text-light">
        [<span>{t('admin.long')}</span>]
      </LongText>
    </AdminTextContainer>
  )
}

const ShortText = styled(Text)`
  > span {
    margin: 0 2px;
    font-size: 14px;
  }
`
const LongText = styled(Text)`
  display: none;
  > span {
    margin: 0 2px;
    font-size: 14px;
  }
`
const AdminTextContainer = styled.span`
  padding-left: 6px;
  padding-right: 6px;
  &:hover {
    ${ShortText} {
      display: none;
    }
    ${LongText} {
      display: inline;
    }
  }
`
