import { forwardRef, useRef } from 'react'
import { Placement } from '@popperjs/core'
import { Button as GrommetButton, ButtonExtendedProps as GrommetButtonProps } from 'grommet'
import styled, { css } from 'styled-components/macro'
import { MergeExclusive, SetOptional, SetRequired } from 'type-fest'
import { useMergeRefs } from 'use-callback-ref'

import { Badge, BadgeProps } from '../../badge'
import { Icon, IconName, IconProps, SpinnerIcon } from '../../icon'
import { Box } from '../../layout/box'
import { Tooltip } from '../../overlays/tooltip'
import { themeColor } from '../../theme'

type OmmittedGrommetProps = Omit<GrommetButtonProps, 'primary' | 'secondary' | 'icon' | 'ref' | 'css' | 'badge'>
type SharedButtonProps = OmmittedGrommetProps & {
  icon?: IconName
  iconColor?: IconProps['color']
  iconPlain?: boolean
  loading?: boolean
  loadingLabel?: string
  full?: boolean
  tipPlacement?: Placement
  tipDelay?: number
  disableTooltip?: boolean
  customStyle?: 'sidebar' | 'view-toggle' | 'secondary-alt'
  isActive?: boolean
  invertColor?: boolean
}

type PrimaryButtonProp = { primary: true; secondary?: false; tertiary?: false }
type SecondaryButtonProp = { secondary: true; primary?: false; tertiary?: false }
type TertiaryButtonProp = { tertiary: true; primary?: false; secondary?: false }
type PlainButtonProp = { primary?: false; secondary?: false; tertiary?: false; plain: true }

type PrimaryOrSecondaryButtonProps = MergeExclusive<PrimaryButtonProp, SecondaryButtonProp>
type PrimaryOrSecondaryOrTertiaryButtonProps = MergeExclusive<PrimaryOrSecondaryButtonProps, TertiaryButtonProp>
type PrimaryOrSecondaryOrTertiaryOrPlainButtonProps = MergeExclusive<
  PrimaryOrSecondaryOrTertiaryButtonProps,
  PlainButtonProp
>

type PlainPropsOrStyledProps = MergeExclusive<
  PrimaryOrSecondaryOrTertiaryOrPlainButtonProps,
  PrimaryOrSecondaryOrTertiaryButtonProps
>

export type ButtonProps = PlainPropsOrStyledProps & SharedButtonProps

export type IconButtonProps = SetOptional<
  SetRequired<Omit<ButtonProps, 'full' | 'reverse'>, 'icon' | 'label'>,
  'tertiary'
> & {
  badge?: BadgeProps
  invertColor?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { label, icon, full, size = 'large', onClick, onKeyUp, disabled, iconColor, loading, loadingLabel, ...props },
    ref
  ) => {
    const localRef = useRef<HTMLButtonElement>(null)
    const buttonRef = useMergeRefs([ref, localRef])
    let labelText = loading && loadingLabel ? loadingLabel : label
    if (typeof labelText === 'string' && labelText.length > 0) {
      labelText = labelText[0].toUpperCase() + labelText.substring(1)
    }
    const hasIcon = icon ? true : false

    // TODO should get rid of these & make all buttons repeatable styled comps
    if (props.plain) {
      return (
        <GrommetButton
          {...props}
          onClick={onClick}
          onKeyUp={onKeyUp}
          label={labelText}
          icon={icon ? <Icon size={size} color={iconColor} icon={icon} /> : undefined}
        />
      )
    }

    return (
      <ButtonInner
        {...props}
        type={props.type ?? 'button'}
        onClick={onClick}
        onKeyUp={onKeyUp}
        full={full}
        size={size}
        disabled={loading ? true : disabled}
        ref={buttonRef}
        a11yTitle={labelText}
        hasIcon={hasIcon} //this is because we need to adjust padding based on if icon or not
      >
        {loading ? (
          <SpinnerIcon size={size} aria-hidden="true" aria-label="icon" />
        ) : icon ? (
          <Icon icon={icon} size={size} aria-hidden="true" aria-label="icon" />
        ) : undefined}
        {labelText}
      </ButtonInner>
    )
  }
)

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      label,
      icon,
      size = 'large',
      onClick,
      onKeyUp,
      tipPlacement,
      disabled,
      disableTooltip,
      tipDelay = 400,
      badge,
      loading,
      ...props
    },
    ref
  ) => {
    const iconButtonSize = size === 'small' ? '24px' : size === 'medium' ? '32px' : '40px'

    const localRef = useRef<HTMLButtonElement>(null)
    const buttonRef = useMergeRefs([ref, localRef])

    const buttonElement = (
      <IconButtonInner
        {...props}
        type={props.type ?? 'button'}
        onClick={onClick}
        onKeyUp={onKeyUp}
        size={size}
        disabled={loading ? true : disabled}
        ref={buttonRef}
        a11yTitle={props.a11yTitle ?? label}
      >
        {loading ? (
          <SpinnerIcon size={size} aria-hidden="true" aria-label="icon" />
        ) : icon ? (
          <Icon icon={icon} size={size} aria-hidden="true" aria-label="icon" />
        ) : undefined}
      </IconButtonInner>
    )

    // Note, having a tooltip forces cursor:pointer even if disabled
    // So forcing disabled to not have a tooltip at all
    const buttonComponent = (
      <>
        {disabled || disableTooltip || loading ? (
          buttonElement
        ) : (
          <Tooltip content={label} delay={tipDelay} placement={tipPlacement} disableOnClickAsTriggerProp>
            {buttonElement}
          </Tooltip>
        )}
      </>
    )

    return !badge ? (
      buttonComponent
    ) : (
      <Badge
        {...badge}
        overlap="round"
        itemHeight={iconButtonSize}
        itemWidth={iconButtonSize}
        anchor={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box height={iconButtonSize} width={iconButtonSize}>
          {buttonComponent}
        </Box>
      </Badge>
    )
  }
)

// NOTE this is not ideal since tooltip should be defined here, but currently only used once so not important
// Known bug with tooltip comp overriding the cursor:pointer on the button
export const AvatarButton = styled(Button).attrs(() => ({ plain: true }))`
  padding: 8px;
  border-radius: 20px;
  cursor: pointer !important;
  &:hover {
    background-color: ${themeColor('nav-item-bg-hover')};
  }
`

// note this is basically same styling as ItemCreateButton, need to consolidate
// only used for adding custom saved view group in main nav currently
export const AddGroupButton = styled(GrommetButton)`
  font-size: 15px;
  color: ${themeColor('nav-item-add-custom-group-button')};
  svg {
    fill: ${themeColor('nav-item-add-custom-group-button')};
  }

  &:hover,
  &:focus-visible {
    color: ${themeColor('nav-item-add-custom-group-button-hover')};
    svg {
      fill: ${themeColor('nav-item-add-custom-group-button-hover')};
    }
  }

  > div {
    justify-content: start;
    padding-left: 6px;
    > div {
      width: 10px;
    }
  }
`
//TODO diff between these should be typed, eg viewtoggle needs isActive
export const SidebarIconButton = styled(IconButton).attrs({ customStyle: 'sidebar' })``
export const ViewToggleIconButton = styled(IconButton).attrs({ customStyle: 'view-toggle' })``

// NOTE as="div" since this needs to be used inside other Button elems, eg accordion header
// ISSUE - why does this need to be used like <PlainTextButton>{t('clearAll')}</PlainTextButton> instead of using label attr as normal
export const PlainTextButton = styled(GrommetButton).attrs(() => ({
  plain: true,
  as: 'div',
  role: 'button',
  tabIndex: 0
}))`
  cursor: pointer;
  text-decoration: underline;
  color: ${themeColor('text-light')};
  &:hover,
  &:focus-visible {
    color: ${themeColor('text')};
  }
`

// TODO: consolidate with PlainTextButton.
// For use when we want a button to appear as a text link, yet be used to perform a function rather than navigate
export const LinkButton = styled(Button).attrs(() => ({
  plain: true,
  tabIndex: 0
}))<{ size?: 'small' | 'medium' | 'large'; highlight?: boolean }>`
  background-color: transparent;
  font-size: ${props => (props.size === 'small' ? '13px' : props.size === 'large' ? '17px' : '15px')};
  cursor: pointer;
  color: ${props => (props.highlight ? themeColor('primary') : themeColor('text-light'))};
  &:hover,
  &:focus-visible {
    text-decoration: underline;
    color: ${props => (props.highlight ? themeColor('primary-2') : themeColor('text'))};
  }
`

// NOTE on death row - only used on old react views
export const FloatingActionButton = styled(IconButton).attrs(() => ({
  primary: true,
  label: 'Add',
  icon: 'add',
  disableTooltip: true,
  'data-testid': 'main-add-button'
}))`
  z-index: 5;
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 64px !important;
  height: 64px !important;
  border-radius: 50%;
  line-height: 64px;
  text-align: center;
  background-color: ${themeColor('primary')};
  box-shadow: 0 2px 4px ${themeColor('text-disabled')};
  padding: 0 16px !important;
  border-radius: 50% !important;

  &:hover {
    background-color: ${themeColor('primary-2')};
    box-shadow: 0 4px 16px ${themeColor('text-disabled')};
  }
  svg {
    fill: ${themeColor('bg')} !important;
  }
`

const calcButtonColors = (props: IconButtonProps | ButtonProps) => {
  if (props.primary) {
    return css`
      color: ${themeColor('bg')};
      background-color: ${props.isActive ? themeColor('primary-2') : themeColor('primary')};
      svg {
        fill: ${themeColor('bg')};
      }
      &:hover {
        background-color: ${props.isActive ? themeColor('primary-3') : themeColor('primary-2')};
      }
      &:focus,
      &:active,
      &[aria-expanded='true'][aria-haspopup='true'] {
        background-color: ${themeColor('primary-2')};
      }
    `
  } else if (props.customStyle === 'secondary-alt') {
    // Custom styles for non-admin role pill
    return css`
      color: ${themeColor('text-light')};
      background-color: ${props.isActive ? themeColor('bg-2') : themeColor('bg-1')};
      svg {
        fill: ${themeColor('text-light')};
      }
      &:hover {
        background-color: ${props.isActive ? themeColor('bg-3') : themeColor('bg-2')};
      }
      &:focus,
      &:active,
      &[aria-expanded='true'][aria-haspopup='true'] {
        background-color: ${themeColor('bg-2')};
      }
    `
  } else if (props.secondary) {
    return css`
      color: ${themeColor('primary')};
      background-color: ${props.isActive ? themeColor('primary-bg-2') : themeColor('primary-bg')};
      svg {
        fill: ${themeColor('primary')};
      }
      &:hover {
        background-color: ${props.isActive ? themeColor('primary-bg-3') : themeColor('primary-bg-2')};
      }
      &:focus,
      &:active,
      &[aria-expanded='true'][aria-haspopup='true'] {
        background-color: ${themeColor('primary-bg-2')};
      }
    `
  } else if (props.customStyle === 'sidebar') {
    return css`
      color: ${props.isActive ? themeColor('nav-item-text-active') : themeColor('nav-item-text')};

      background-color: ${props.isActive ? themeColor('nav-item-bg-active') : 'transparent'};

      svg {
        fill: ${props.isActive ? themeColor('nav-item-text-active') : themeColor('nav-item-text')};
      }
      &:hover {
        background-color: ${props.isActive ? themeColor('nav-item-bg-active') : themeColor('nav-item-bg-hover')};
      }
      &:focus,
      &:active,
      &[aria-expanded='true'][aria-haspopup='true'] {
        background: ${themeColor('nav-item-bg-active')};
      }
    `
  } else if (props.customStyle === 'view-toggle' && props.invertColor) {
    return css`
      background-color: ${props.isActive ? themeColor('bg-1') : 'transparent'};

      svg {
        fill: ${props.isActive ? themeColor('text') : themeColor('text-light')};
      }
      &:hover {
        background-color: ${props.isActive ? themeColor('bg-2') : themeColor('bg-1')};
      }
      &:focus,
      &:active,
      &[aria-expanded='true'][aria-haspopup='true'] {
        background-color: ${props.isActive ? themeColor('bg-1') : themeColor('bg-2')};
      }
    `
  } else if (props.customStyle === 'view-toggle') {
    return css`
      background-color: ${props.isActive ? themeColor('bg') : 'transparent'};

      svg {
        fill: ${props.isActive ? themeColor('text') : themeColor('text-light')};
      }
      &:hover {
        background-color: ${props.isActive ? themeColor('bg') : themeColor('bg-2')};
      }
      &:focus,
      &:active,
      &[aria-expanded='true'][aria-haspopup='true'] {
        background-color: ${props.isActive ? themeColor('bg') : themeColor('bg-3')};
      }
    `
  } else if (props.iconPlain) {
    return css`
      color: ${themeColor('text-light')};
      background-color: ${props.isActive ? themeColor('bg-2') : 'transparent'};
      svg {
        fill: ${themeColor('text-light')};
      }
    `
  } else {
    return css`
      color: ${themeColor('text-light')};
      background-color: ${props.isActive ? themeColor('bg-1-alpha') : 'transparent'};
      svg {
        fill: ${themeColor('text-light')};
      }
      &:hover {
        background-color: ${props.isActive ? themeColor('bg-3') : themeColor('bg-1-alpha')};
      }
      &:focus,
      &:active,
      &[aria-expanded='true'][aria-haspopup='true'] {
        background: ${themeColor('bg-1-alpha')};
      }
    `
  }
}

const IconButtonInner = styled(Box).attrs(() => ({
  tag: 'button'
}))<IconButtonProps>`
  z-index: 1;
  justify-content: center;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  min-width: auto;
  border-radius: 50%;
  outline-offset: 2px !important;
  padding: 0;
  border: none;
  cursor: pointer;

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  ${({ size }) => {
    if (size === 'small') {
      return css`
        height: 24px;
        width: 24px;
      `
    } else if (size === 'medium') {
      return css`
        height: 32px;
        width: 32px;
      `
    } else {
      return css`
        height: 40px;
        width: 40px;
      `
    }
  }}

  ${props => calcButtonColors(props)}
`

const ButtonInner = styled(Box).attrs(() => ({
  as: 'button'
}))<ButtonProps & { hasIcon: boolean }>`
  z-index: 1;
  justify-content: center;
  display: flex;
  align-self: center;
  flex-shrink: 0;
  align-items: center;
  min-width: auto;
  outline-offset: 2px !important;
  padding: 0;
  border: none;
  cursor: pointer;
  font-weight: 500;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  width: ${({ full }) => (full ? '100%' : 'auto')};

  ${({ size, hasIcon, reverse }) => {
    if (size === 'small') {
      return css`
        gap: 2px;
        border-radius: 12px;
        padding: ${hasIcon && !reverse ? '0 8px 0 5px' : hasIcon && reverse ? '0 5px 0 8px' : '0 8px'};
        font-size: 13px;
        height: 24px;
      `
    } else if (size === 'medium') {
      return css`
        gap: 3px;
        border-radius: 16px;
        padding: ${hasIcon && !reverse ? '0 12px 0 8px' : hasIcon && reverse ? '0 8px 0 12px' : '0 12px'};
        font-size: 15px;
        height: 32px;
      `
    } else {
      return css`
        gap: 4px;
        border-radius: 20px;
        padding: ${hasIcon && !reverse ? '0 16px 0 11px' : hasIcon && reverse ? '0 11px 0 16px' : '0 16px'};
        height: 40px;
      `
    }
  }}

  ${props => calcButtonColors(props)}
`

Button.displayName = 'CutoverButton'
IconButton.displayName = 'CutoverIconButton'
