import { ReactNode, useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useUnmount } from 'react-use'
import { useQueryClient } from 'react-query'
import { isEqual } from 'lodash'

import { useWebsockets } from 'main/services/hooks'
import { getInfiniteRunbookCacheKey } from 'main/services/queries/use-runbooks-infinite'
import { RunbookListRunbook } from 'main/services/queries/types'
import { QueryKeys } from 'main/services/queries/query-keys'
import { RunbooksResponseMetaFull } from 'main/services/queries/use-runbooks'
import { CurrentUserModel } from 'main/data-access'

type RunbooksListInfiniteQueryData =
  | { pages: { meta: RunbooksResponseMetaFull; runbooks: RunbookListRunbook[] }[]; pageParams: any[] }
  | undefined

export const AccountChannelSubscriber = ({ children }: { children?: ReactNode }) => {
  const { subscribe, findExistingSubscription } = useWebsockets()
  const { accountId: accountSlug, runbookId } = useParams<{ accountId?: string; runbookId?: string }>()
  const currentUserId = CurrentUserModel.useId()
  const processAccountResponse = useHandleAccountResponse(accountSlug)

  const websocketRef = useRef<ActionCable.Channel | undefined>()

  useEffect(() => {
    websocketRef.current?.unsubscribe?.()

    if (!accountSlug || runbookId) return

    const existingRunbookChannel = findExistingSubscription('AccountChannel', accountSlug)

    if (existingRunbookChannel) return

    websocketRef.current = subscribe('AccountChannel', accountSlug, {
      received: response => {
        const [sameTab, sameUser] = [
          response.meta?.headers?.request_hash === window.sessionStorage.getItem('browserHash'),
          response.meta?.headers?.request_user_id === currentUserId
        ]

        if (sameTab && sameUser) return

        processAccountResponse(response)
      }
    })
  }, [accountSlug, runbookId])

  useUnmount(() => {
    websocketRef.current?.unsubscribe?.()
  })

  return <>{children}</>
}

const updateCacheData = (responseRunbook: RunbookListRunbook, prevData?: RunbooksListInfiniteQueryData) => {
  if (!prevData) return

  return {
    ...prevData,
    pages: prevData.pages.map(page => {
      return {
        ...page,
        meta: { ...page.meta, filtered_results_count: (page.meta.filtered_results_count -= 1) },
        runbooks: page?.runbooks.filter(runbook => runbook.id !== responseRunbook.id)
      }
    })
  }
}

const useHandleAccountResponse = (accountSlug?: string) => {
  const queryClient = useQueryClient()

  return useCallback(
    (response: {
      meta: {
        headers: { request_class: string; request_method: string; request_user_id: number }
        allowed_users: number[]
      }
      made_restricted: boolean
      runbook: RunbookListRunbook
    }) => {
      if (response.meta.headers.request_class === 'Runbook' && response.made_restricted) {
        // remove all runbooks queries for this workspace except in the current view
        queryClient.removeQueries({
          predicate: query =>
            query.queryKey[0] === accountSlug &&
            query.queryKey[1] === QueryKeys.Runbooks &&
            !isEqual(query.queryKey, getInfiniteRunbookCacheKey())
        })

        // update the cache for active filtered runbooks list in current view (ie: dashboard right panel)
        queryClient.setQueryData<RunbooksListInfiniteQueryData>(
          getInfiniteRunbookCacheKey(),
          (prevData: RunbooksListInfiniteQueryData) => {
            return updateCacheData(response.runbook, prevData)
          }
        )
      } else if (response.meta.headers.request_class === 'Runbook') {
        queryClient.invalidateQueries(getInfiniteRunbookCacheKey())
      }
    },
    []
  )
}
