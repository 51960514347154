import { useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FormModal } from 'main/components/shared/form'
import { ActiveRunbookModel, ActiveRunbookVersionModel } from 'main/data-access'
import { useCancelApproval } from 'main/services/queries/use-runbook-versions'
import { RunbookVersionCancelApprovalResponse } from 'main/services/api/data-providers/runbook-types'

type CancelApprovalModalProps = {
  onClose?: () => void
}

export const CancelApprovalModal = ({ onClose }: CancelApprovalModalProps) => {
  const { t } = useLanguage('runbook')
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const { mutateAsync: cancelApproval, isLoading } = useCancelApproval(runbookId, runbookVersionId)
  const processCancelApproval = ActiveRunbookVersionModel.useOnAction('cancel_approval')
  const notify = useNotify()

  const handleSubmit = async () => {
    return await cancelApproval()
  }

  const handleSuccess = (response: RunbookVersionCancelApprovalResponse) => {
    processCancelApproval(response)
    notify.success(t('cancelApprovalModal.cancelSuccessText'))
  }

  return (
    <FormModal
      open
      title={t('cancelApprovalModal.title')}
      description={t('cancelApprovalModal.description')}
      confirmIcon="cancel"
      confirmText={t('cancelApprovalModal.confirmText')}
      loadingText={t('cancelApprovalModal.confirmingText')}
      loading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      hasCancelButton={false}
    />
  )
}
