import * as yup from 'yup'

import { useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FormModal, UserSelectField } from 'main/components/shared/form'
import { ActiveAccountModel, ActiveRunbookModel, ActiveRunbookVersionModel } from 'main/data-access'
import {
  RequestApprovalPayload,
  useCreateNewRunbookVersion,
  useRequestApproval
} from 'main/services/queries/use-runbook-versions'
import {
  RunbookVersionCreateResponse,
  RunbookVersionRequestApprovalResponse
} from 'main/services/api/data-providers/runbook-types'

type RequestApprovalModalProps = {
  onClose?: () => void
  reapproval?: boolean
}

const validationSchema = yup.object({
  reviewers: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required()
      })
    )
    .required()
})

export type RequestApprovalFormType = yup.InferType<typeof validationSchema>

export const RequestApprovalModal = ({ onClose, reapproval }: RequestApprovalModalProps) => {
  const { t } = useLanguage('runbook')
  const processRunbookVersionCreateResponse = ActiveRunbookVersionModel.useOnAction('create')
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const accountId = ActiveAccountModel.useId()
  const notify = useNotify()
  const { mutateAsync: requestApproval, isLoading: isApprovalLoading } = useRequestApproval(runbookId, runbookVersionId)
  const { mutateAsync: createVersion, isLoading: isReapprovalLoading } = useCreateNewRunbookVersion(runbookId)

  const processRequestApproval = ActiveRunbookVersionModel.useOnAction('request_approval')

  // Note: For reasons unknown, a 'reapproval' hits the version create endpoint with a custom payload
  const handleSubmit = async (data: RequestApprovalPayload) => {
    if (reapproval) {
      return await createVersion({
        base_version_id: runbookVersionId,
        reapproval: true,
        reviewer_ids: data.reviewer_ids
      })
    } else {
      return await requestApproval(data)
    }
  }

  const handleSuccess = (response: RunbookVersionRequestApprovalResponse | RunbookVersionCreateResponse) => {
    if (reapproval) {
      processRunbookVersionCreateResponse(response as RunbookVersionCreateResponse)
      notify.success(t('requestApprovalModal.reapprovalSuccessText'))
    } else {
      processRequestApproval(response as RunbookVersionRequestApprovalResponse)
      notify.success(t('requestApprovalModal.approvalSuccessText'))
    }
    onClose?.()
  }

  return (
    <FormModal<RequestApprovalFormType, RequestApprovalPayload>
      open
      title={t('requestApprovalModal.title')}
      confirmText={t('requestApprovalModal.confirmText')}
      description={t('requestApprovalModal.description')}
      loadingText={t('requestApprovalModal.confirmingText')}
      loading={isApprovalLoading || isReapprovalLoading}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      onClose={onClose}
      schema={validationSchema}
      transformer={dataTransformer}
      preventAutoClose
    >
      <UserSelectField<RequestApprovalFormType>
        name="reviewers"
        accountId={accountId}
        single
        label={t('requestApprovalModal.fields.reviewer.label')}
      />
    </FormModal>
  )
}

const dataTransformer = (data: RequestApprovalFormType): RequestApprovalPayload => {
  return {
    reviewer_ids: data.reviewers.map(user => user.id)
  }
}
