import { useState } from 'react'

import { AccordionPanel, IconButton, IconName, TextInput } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ConfigModel } from 'main/data-access'

type TaskWebhooksProps = {
  iconColor: string
  runbookId: number
  taskInternalId: number
}

export const TaskWebhooks = ({ iconColor, runbookId, taskInternalId }: TaskWebhooksProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.webhooks' })
  const [icon, setIcon] = useState<IconName>('copy')
  const config = ConfigModel.useGet()

  const URI = `${config.cutoverApiFullPath}/api/runbooks/${runbookId}/runbook_versions/current_version/tasks/${taskInternalId}?id_key=internal_id`

  const copyURI = () => {
    navigator.clipboard.writeText(URI)
    setIcon('check')

    setTimeout(() => {
      setIcon('copy')
    }, 2000)
  }

  return (
    <AccordionPanel label={t('accordionLabel')} iconColor={iconColor} icon="webhooks">
      <TextInput
        disabled // always
        label={t('taskURI')}
        value={URI}
        css="padding-right: 10px"
        endComponent={<IconButton size="small" icon={icon} label={t('copy')} onClick={copyURI} />}
      />
    </AccordionPanel>
  )
}
