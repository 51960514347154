import { forwardRef, memo, MutableRefObject, SyntheticEvent, useMemo } from 'react'
import sync from 'css-animation-sync'
import { Box, Stack, Text } from 'grommet'
import styled, { css, keyframes } from 'styled-components/macro'

import { IconName, TaskTypeIconName } from '@cutover/icons'
import { Icon, SpinnerIcon, TaskTypeIcon } from '../icon'
import { resolveColor, themeColor, ThemeType } from '../theme'
import { TaskIconConnector } from './task-item-icon-connector'

// Keeps task icon animation in sync
sync('rotate')

export type DependencyType = 'predecessors' | 'successors'

export type TaskItemIconProps = {
  color: string
  disabled?: boolean
  disableNotify?: boolean
  icon: TaskTypeIconName
  iconSize?: keyof ThemeType['icon']['size']
  inProgress?: boolean
  isLoading?: boolean
  isOpaque?: boolean
  onClick?: (e: SyntheticEvent) => void
  onClickDependency?: (type: DependencyType) => void
  predecessorCountLabel?: number
  stageIcon?: IconName
  hoverIcon?: IconName
  successorCountLabel?: number
  withConnectors?: { previousIcon?: TaskTypeIconName; nextIcon?: TaskTypeIconName; bold?: boolean; dotted?: boolean }
  tabIndex?: number
}

export const TaskItemIcon = memo(
  forwardRef(
    (
      {
        color,
        disabled,
        disableNotify,
        icon,
        iconSize = 'large',
        inProgress,
        isLoading,
        isOpaque = true,
        onClick,
        onClickDependency,
        predecessorCountLabel,
        stageIcon,
        hoverIcon,
        successorCountLabel,
        withConnectors,
        tabIndex
      }: TaskItemIconProps,
      ref
    ) => {
      const connectorStyles = useMemo(
        () =>
          withConnectors
            ? taskConnectorLengthHelper(
                icon,
                withConnectors.previousIcon,
                withConnectors.nextIcon,
                withConnectors.bold,
                withConnectors.dotted
              )
            : undefined,
        [withConnectors?.nextIcon, withConnectors?.previousIcon, withConnectors?.dotted, withConnectors?.bold, icon]
      )

      const iconBoxSize: Partial<Record<keyof ThemeType['icon']['size'], string>> = {
        large: '48px',
        xsmall: '24px'
      }
      const iconSizeValue = iconBoxSize[iconSize]
      const clickable = !!onClick

      return (
        <Box
          data-testid="task-list-item-button"
          align="center"
          justify="center"
          as={clickable ? 'button' : undefined}
          css={`
            padding: 0;
            border: 0;
            background: none;
            height: ${iconSizeValue};
            width: ${iconSizeValue};
            cursor: ${clickable ? 'pointer' : 'default'};
          `}
          onClick={clickable ? e => onClick(e) : undefined}
          tabIndex={tabIndex}
        >
          <StyledStack
            anchor="center"
            clickable={clickable}
            disabled={disabled}
            data-testid={`task-item-icon-${icon}`}
            isOpaque={isOpaque}
            fill
          >
            <TaskTypeIcon
              size={iconSizeValue}
              data-testid={`task-item-icon-${icon}-display`}
              icon={icon}
              color={color as string}
            />
            {inProgress && <CircularMotion className="task-item-bg circular-motion" />}
            {connectorStyles?.connectorUpStyle && (
              <TaskIconConnector
                up
                data-testid="task-item-connector-up"
                color={color}
                {...connectorStyles.connectorUpStyle}
              />
            )}
            {predecessorCountLabel && (
              <ConnectedTasksIndicator
                ref={ref}
                color={color}
                count={predecessorCountLabel}
                type="predecessors"
                onClick={e => {
                  e.stopPropagation()
                  onClickDependency?.('predecessors')
                }}
              />
            )}
            {successorCountLabel && (
              <ConnectedTasksIndicator
                ref={ref}
                count={successorCountLabel}
                type="successors"
                onClick={e => {
                  e.stopPropagation()
                  onClickDependency?.('successors')
                }}
              />
            )}
            <Box>
              {isLoading ? (
                <SpinnerIcon color={color} />
              ) : stageIcon ? (
                <TaskItemStageIcon
                  size={iconSize}
                  className={hoverIcon ? 'task-item-remove-on-task-hover' : ''}
                  icon={stageIcon}
                  color={color}
                />
              ) : null}
              {hoverIcon && <Icon className="task-item-display-on-task-hover" icon={hoverIcon} color={color} />}
            </Box>

            {disableNotify && (
              <MuteIcon data-testid="disable-notify-icon" icon="volume-mute" color="text-light" size="small" />
            )}
            {connectorStyles?.connectorDownStyle && <TaskIconConnector up={false} color={color} />}
          </StyledStack>
        </Box>
      )
    }
  )
)

const TaskItemStageIcon = styled(Icon)``

const StyledStack = styled(Stack)<{ clickable?: boolean; disabled?: boolean; isOpaque?: boolean }>`
  ${({ clickable }) =>
    clickable
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: default;
          pointer-events: none;
        `}

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        ${TaskItemStageIcon} {
          opacity: 1;
        }
      `}
  }

  ${({ disabled, isOpaque }) =>
    disabled
      ? css`
          ${TaskItemStageIcon}, ${SpinnerIcon} {
            opacity: ${isOpaque ? 1 : 0.3};
          }
        `
      : css`
          ${TaskItemStageIcon}, ${SpinnerIcon} {
            opacity: ${isOpaque ? 1 : 0.7};
          }
        `}
`

const ConnectedTasksIndicator = memo(
  forwardRef(
    (props: { count: number; type: DependencyType; color?: string; onClick?: (e: SyntheticEvent) => void }, ref) => {
      return (
        <Box
          ref={ref as MutableRefObject<HTMLDivElement>}
          data-testid={props.type === 'predecessors' ? 'task-predecessors-icon' : 'task-successors-icon'}
          background={props.type === 'predecessors' ? props.color ?? 'primary' : 'bg-4'}
          border={{ color: 'bg', size: '1px' }}
          align="center"
          justify="center"
          round
          onClick={props.onClick}
          height="16px"
          css={`
            pointer-events: auto; // need this to override the pointer-events: none on the parent since this is a child but is overlayed the icon container
            cursor: ${props.onClick ? 'pointer' : 'default'};
            z-index: 1;
            min-width: 16px;
            text-align: center;
            position: relative;
            padding: 0 2px;
            top: ${props.type == 'successors' ? '12px' : '-13px'};
            left: ${props.type == 'successors' ? '-12px' : '-13px'};
            border-top-left-radius: ${props.type === 'predecessors' ? 0 : undefined};
            border-bottom-left-radius: ${props.type === 'successors' ? 0 : undefined};
            transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
            transform-origin: center;
            &:hover {
              transform: scale(1.5, 1.5);
            }
          `}
        >
          <Text color={props.type === 'predecessors' ? 'white' : 'text-light'} size="11px">
            {props.count}
          </Text>
        </Box>
      )
    }
  )
)

const taskConnectorLengthHelper = (
  icon: TaskTypeIconName,
  prevIcon?: TaskTypeIconName,
  nextIcon?: TaskTypeIconName,
  bold?: boolean,
  dotted?: boolean
) => {
  return {
    connectorUpStyle: {
      prevConnectorLong: prevIcon ? ['square', 'box', 'runbook'].indexOf(prevIcon) > -1 : false,
      prevConnectorShort: prevIcon ? ['diamond', 'hexagon', 'cog'].indexOf(prevIcon) > -1 : false,
      nextConnectorShort: icon ? ['diamond', 'hexagon', 'triangle', 'cog'].indexOf(icon) > -1 : false,
      nextConnectorLong: prevIcon ? ['square', 'box', 'runbook'].indexOf(icon) > -1 : false,
      dotted,
      bold
    },
    connectorDownStyle: !!nextIcon
  }
}

const MuteIcon = styled(Icon)`
  position: absolute;
  left: 6px;
  bottom: -20px;
  border-radius: 50%;
  border: 1px solid ${themeColor('white')};
  background-color: ${themeColor('white')};
  text-align: center;
`
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const CircularMotion = styled(Box)`
  animation-name: 'rotate';
  animation: ${rotate} 1.5s infinite linear;
  background-color: ${({ theme }) => resolveColor('bg', theme)};
  display: block;
  height: 3px;
  left: 9px;
  position: relative;
  transform-origin: 0% 50%;
  width: 19px;
`
