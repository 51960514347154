import { useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { isEmpty } from 'lodash'

import { Box, SpinnerIcon, TextEditor } from '@cutover/react-ui'
import { useCustomFieldForm } from 'main/components/shared/custom-field-form'
import { LinkedSnippet } from 'main/services/queries/types'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helper'
import { CustomFieldsGroupsForm } from 'main/components/shared/custom-field-form/custom-field-groups-form'
import { useLanguage } from 'main/services/hooks'
import { FormModal } from 'main/components/shared/form'
import { useModalCheckTaskStageChanged } from '../use-modal-check-task-stage-changed'
import { getTask, validateAbandonTask } from 'main/services/queries/use-task'
import { TaskConditionalProgressionContent } from './task-conditional-progression-content'
import { SelectSnippet } from 'main/components/runbook/modals/snippets/start-snippet-task-type/select-snippet'
import { SnippetListSnippet } from 'main/services/queries/use-snippets'
import { validateMerge } from 'main/services/queries/validate-merge-query'
import { mergeRunbooks } from 'main/services/queries/merge-runbooks-query'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  CustomFieldGroupModel,
  CustomFieldModel,
  CustomFieldUserModel,
  RunbookViewModel,
  TaskModel,
  TaskTypeModel
} from 'main/data-access'
import { RunbookTaskShowResponse } from 'main/services/api/data-providers/runbook-types'

const formType = createValidationSchema(yup.object())
export type CustomFieldsFormSchema = yup.InferType<typeof formType>

type TaskActionProps = {
  id: number
  onClose: () => void
}

export type SuccessorTask = {
  id: number
  name: string
  selected: boolean
}

export const TaskActionModal = ({ id, onClose }: TaskActionProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskActionModal' })

  const continueModal = RunbookViewModel.useAction('modal:continue')
  const progressTask = TaskModel.useAction('progress')
  const processMergeSnippetResponse = ActiveRunbookModel.useOnAction('merge')

  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState<string[]>([])
  const [taskShowData, setTaskShowData] = useState<undefined | RunbookTaskShowResponse>(undefined)
  const [selectedSnippet, setSelectedSnippet] = useState<SnippetListSnippet | LinkedSnippet | undefined>(undefined)

  /* ------ Data ----- */
  const { id: runbookId, settings_task_description_on_task_start: showTaskDescription } = ActiveRunbookModel.useGet()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const { history: modalHistory } = RunbookViewModel.useGet('modal')

  const taskLookup = TaskModel.useGetLookup()
  const task = TaskModel.useGet(id)
  const taskType = TaskTypeModel.useGet(task?.task_type_id)

  const customFieldGroupsLookup = CustomFieldGroupModel.useGetLookup()
  const customFieldGroups = CustomFieldGroupModel.useGetAll()
  const customFieldUsers = CustomFieldUserModel.useGetAll()
  const customFieldsLookup = CustomFieldModel.useGetLookup()

  const initialTaskStage = useMemo(() => task.stage, [])
  const isTaskStart = task.stage === 'startable'
  const isTaskTypeConditionalProgression =
    taskType.conditional_progression &&
    (task.stage === 'in-progress' || (task.stage === 'startable' && taskType.auto_finish))
  const shouldSubmitWithSnippet = taskType.key === 'snippet' && selectedSnippet

  const [successorTasks, setSuccessorTasks] = useState<SuccessorTask[]>(
    task.successor_ids
      .map(id => ({ name: taskLookup[id].name, id: taskLookup[id].id, selected: true }))
      .sort((a, b) => a.name.localeCompare(b.name))
  )

  const animateValue = () => {
    if (modalHistory.length > 0) {
      return isTaskTypeConditionalProgression ? false : 'out'
    } else {
      return isTaskTypeConditionalProgression ? 'in' : true
    }
  }

  /* ------- Form ------- */
  const {
    fieldValueValidation,
    buildFieldValuesAttributesRequestData,
    data: {
      customFields,
      groupedCustomFields,
      context: { includesRequiredCfs }
    }
  } = useCustomFieldForm({
    applyToSlugs: isTaskStart ? ['task_start'] : ['task_end'],
    customFieldsLookup,
    customFieldGroups,
    constraintContext: { task_type_id: taskType.id }
  })
  const defaultValues = {
    field_values: buildDefaultFieldValues(customFields, groupedCustomFields)
  }
  const transformer = (data: CustomFieldsFormSchema) => {
    return {
      field_values: data.field_values && buildFieldValuesAttributesRequestData(data.field_values)
    }
  }
  const hasCustomFields = !isEmpty(groupedCustomFields) || customFields.length > 0

  const handleValidateAbandon = async (formData: { field_values?: any }) => {
    const selected_successor_ids = successorTasks.filter(task => task.selected).map(task => task.id)
    const data = await validateAbandonTask({
      runbookId,
      runbookVersionId,
      taskId: task.id,
      payload: { selected_successor_ids }
    })

    if (data) {
      const mergedData = { ...data, ...formData, selected_successor_ids }
      return mergedData
    }
  }

  const handleSnippetFormSubmit = async (data: CustomFieldsFormSchema) => {
    if (!selectedSnippet) return // this should never be true but is here for type safety

    await validateMerge({ runbook_ids: [selectedSnippet.id], target_runbook_id: runbookId })

    const mergeResponse = await mergeRunbooks({
      insertion_type: 'sequence',
      runbook_ids: [selectedSnippet.id],
      shift_time: false,
      target_runbook_id: runbookId,
      task_parent_id_to_link_to: task.id
    })
    await processMergeSnippetResponse(mergeResponse)
    setSelectedSnippet(undefined)
    return progressTask(id, { field_values_attributes: data.field_values }) // do not await; err handling + loading is handled w/in this function
  }

  /** @description on why there is an async and non-async submit */
  /** the normal behaviour of the modal is to make the start/finish request and close before
   * it has resolved; we put the loading state onto the task list item and errors are shown in a
   * notification. Because we don't need to wait for the resolution, we don't need the async version.
   * However, some tasks (eg, conditional progression, snippets etc) make other requests before
   * they close (validate_abandon, validate_merge etc), where we need to wait for the outcome
   * of the response before closing the modal, so we use async/await for those.
   */
  const onSubmit = (data: CustomFieldsFormSchema) => progressTask(id, { field_values_attributes: data.field_values })
  const onSubmitAsync = async (data: CustomFieldsFormSchema) => {
    if (isTaskTypeConditionalProgression) return await handleValidateAbandon(data)
    if (taskType.key === 'snippet') return handleSnippetFormSubmit(data)
  }
  const shouldSubmitSynchronously = !isTaskTypeConditionalProgression && !shouldSubmitWithSnippet

  const {
    handleSubmit,
    handleSubmitAwait,
    handleClose,
    confirmIcon,
    confirmText,
    errors: stageChangedErrors,
    renderContent,
    hasCancelButton
  } = useModalCheckTaskStageChanged<CustomFieldsFormSchema>({
    task,
    onSubmit,
    onSubmitAsync,
    onClose,
    confirmIcon: 'chevron-right',
    confirmText: t('confirmText')
  })

  useEffect(() => {
    const shouldGetTaskShowObject =
      isTaskStart && ((task.has_description && showTaskDescription) || taskType.key === 'snippet')
    if (!shouldGetTaskShowObject) return setLoading(false)

    const request = async () => {
      try {
        const response = await getTask({ runbookId, runbookVersionId, taskId: id })
        setLoading(false)
        setTaskShowData(response)
      } catch (e) {
        setLoading(false)
        setErrors([t('taskShowRequestError')])
      }
    }

    request()
  }, [])

  return (
    <FormModal<CustomFieldsFormSchema, any>
      data-testid="modal-task-action"
      animate={animateValue()}
      confirmIcon={confirmIcon}
      confirmText={confirmText}
      defaultValues={defaultValues}
      key="task-action-form"
      onClose={handleClose}
      onSubmit={shouldSubmitSynchronously ? handleSubmit : handleSubmitAwait}
      onSuccess={data => {
        if (data) {
          continueModal({ type: 'task-abandon-confirm', id, data }, { id, type: 'task-action' })
        }
      }}
      hasCancelButton={hasCancelButton}
      open
      schema={createValidationSchema(fieldValueValidation)}
      title={t(initialTaskStage === 'startable' ? 'titleStart' : 'titleFinish')}
      transformer={transformer}
      customErrors={stageChangedErrors || errors}
      loadingText={isTaskTypeConditionalProgression ? t('conditionalProgression.validating') : undefined}
      preventAutoClose={isTaskTypeConditionalProgression}
    >
      {({ formState: { errors: formErrors } }) =>
        renderContent(
          loading ? (
            <Box data-testid="task-action-loading" height={{ min: '80px' }} justify="center" align="center" fill>
              <SpinnerIcon />
            </Box>
          ) : (
            <>
              {taskShowData?.task && taskShowData.task.description && showTaskDescription ? (
                <TextEditor
                  data-testid="task-action-description-field"
                  value={taskShowData.task.description}
                  label={t('description')}
                  readOnly
                  onChange={() => {}}
                />
              ) : null}
              <CustomFieldsGroupsForm
                initialActiveIndex={
                  taskType.key !== 'snippet' || includesRequiredCfs
                    ? Array.from({ length: Object.entries(groupedCustomFields).length + 1 }, (_, index) => index)
                    : []
                }
                customFieldUsers={customFieldUsers}
                customFieldGroupsLookup={customFieldGroupsLookup}
                groupedCustomFields={groupedCustomFields}
                customFields={customFields}
                errors={formErrors}
              />
              {taskType.key === 'snippet' && isTaskStart && (
                <SelectSnippet
                  // remove this withTopMargin prop when/if the snippet select gets its own modal or it has its own form spacing
                  withTopMargin={hasCustomFields}
                  onSelect={setSelectedSnippet}
                  linkedSnippets={taskShowData?.task.linked_snippets || []}
                />
              )}
              {isTaskTypeConditionalProgression && (
                <TaskConditionalProgressionContent
                  successorTasks={successorTasks}
                  setSuccessorTasks={setSuccessorTasks}
                  hasCustomFields={hasCustomFields}
                />
              )}
            </>
          )
        )
      }
    </FormModal>
  )
}

function createValidationSchema(fieldValuesValidation: any) {
  return yup.object({
    field_values: fieldValuesValidation
  })
}
