import { Ref } from 'react'

import { MenuListItemProps } from '@cutover/react-ui'
import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'
import { IntegrationActionItem, TaskListTask } from 'main/services/queries/types'

export type TaskCSVImportModalType = {
  id: never
  type: 'tasks-csv-import'
  data: { status?: 'submitting' | 'success' | 'error' | 'idle'; context?: string[] }
}

type OptionalData<T> = T extends { data: never } ? Omit<T, 'data'> : T
type OptionalId<T> = T extends { id: never } ? Omit<T, 'id'> : T

export type ModalActiveTaskProgressionType =
  | { id: number; type: 'task-action'; data: never }
  | { id: number; type: 'task-override'; data: never }
  | { id: number; type: 'task-override-optional'; data: never }
  | { id: number; type: 'task-override-fixed-start'; data: never }
  | { id: number; type: 'task-finish-confirm'; data: never }
  | { id: never; type: 'task-start-block'; data: never }
  | { id: number; type: 'task-abandon-confirm'; data: TaskAbandonConfirmData }
  | { id: number; type: 'task-oauth'; data: TaskOauthData }

export type ModalActiveTypeBase =
  | ModalActiveTaskProgressionType
  | { id: number; type: 'snippet-add'; data: never }
  | { id: number; type: 'linked-runbook-add'; data: never }
  | { id: number[]; type: 'tasks-delete'; data: never }
  | { id: number[]; type: 'tasks-skip'; data: never }
  | { taskId?: number; taskName?: string; type: 'tasks-paste'; data: never }
  | { taskId: number; name: string; type: 'integration-abort'; data: never }
  | TaskCSVImportModalType
  | { id: never; type: 'runbook-schedule'; data: never }
  | { id: never; type: 'runbook-make-template'; data: never }
  | { id: never; type: 'runbook-duplicate'; data: never }
  | { id: never; type: 'runbook-merge'; data: never }
  | { id: never; type: 'runbook-view-history'; data: never }
  | { id: never; type: 'runbook-archive'; data: never }
  | { id: never; type: 'run-start'; data: never }
  | { id: never; type: 'run-pause'; data: never }
  | { id: never; type: 'run-resume'; data: never }
  | { id: never; type: 'request-approval'; data: never }
  | { id: never; type: 'request-reapproval'; data: never }
  | { id: never; type: 'cancel-approval'; data: never }
  | { id: never; type: 'mark-as-approved'; data: never }
  | { id: never; type: 'submit-review'; data: never }
  | { id: never; type: 'view-review'; data: never }
  | { id: never; type: 'version-create'; data: never }
  | { type: 'tasks-export'; data: never }
  | { type: 'runbook-dashboard-share'; data: never }

export type ModalActiveType = OptionalId<OptionalData<ModalActiveTypeBase>>

export type TaskListMenu = {
  open: boolean
  taskId?: number
  type?: 'options' | 'predecessors' | 'successors'
  triggerRef?: Ref<HTMLElement>
  keyPrefix?: string
  isOpen?: boolean
  items: MenuListItemProps[]
  minWidth?: number
  maxWidth?: number
  maxHeight?: number
}

export type IntegrationRequestType = 'refire' | 'cancel'
export type IntegrationRequest = {
  taskId: number
  type: IntegrationRequestType
}

export type TaskAbandonConfirmData = {
  field_values?: any
  tasks: TaskListTask[]
  selected_successor_ids: number[]
}

type TaskOauthData = {
  integrationActionItem: IntegrationActionItem
}

export type RunbookViewStateType<TModalHistory extends object = {}> = {
  loadingIds: Record<number, boolean>
  selectedIds: number[]
  copyIds: number[]
  highlightMode: boolean
  modal: {
    active?: ModalActiveType
    history: (ModalActiveType & { context?: TModalHistory })[]
  }
  menu: TaskListMenu
  newCommentsCount: number
  integrationRequest: { [x: number]: IntegrationRequestType }
  taskCreate: {
    name: string | undefined
    predecessor: undefined | number
  }
}

export const runbookViewState_INTERNAL = runbookAtom<RunbookViewStateType>({
  key: 'runbook-view-state',
  default: {
    loadingIds: {},
    selectedIds: [],
    copyIds: [],
    highlightMode: false,
    modal: {
      active: undefined,
      history: []
    },
    menu: {
      open: false,
      taskId: undefined,
      type: undefined,
      items: [],
      triggerRef: undefined,
      keyPrefix: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      maxHeight: undefined
    },
    newCommentsCount: 0,
    integrationRequest: {},
    taskCreate: {
      name: undefined,
      predecessor: undefined
    }
  }
})
