import { useState } from 'react'

import { Box, Modal } from '@cutover/react-ui'
import { CurrentUserModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { IntegrationActionItem } from 'main/services/queries/types'

type TaskOauthModalProps = {
  onClose: () => void
  integrationActionItem: IntegrationActionItem
  taskId: number
}

export const TaskOauthModal = ({ onClose, taskId, integrationActionItem }: TaskOauthModalProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskOauthModal' })
  const [loading, setLoading] = useState(false)
  const currentUser = CurrentUserModel.useGet()

  const handleAuthentication = () => {
    setLoading(true)
    const isMSGraph = integrationActionItem?.key?.includes('microsoft-graph')
    const URL = `${location.origin}/api/integrations/oauth/authentications/${
      isMSGraph ? 'microsoft_graph' : 'payload_transformer'
    }/authorize?location=${encodeURIComponent(location.href)}&entity_id=${taskId}&entity_class=Task&user_id=${
      currentUser?.id
    }`
    window.location.href = URL
  }

  return (
    <Modal
      title={t('title')}
      data-testid="modal-task-oauth"
      open
      onClose={onClose}
      confirmText={t('confirmText')}
      onClickConfirm={handleAuthentication}
      loading={loading}
    >
      <Box>{t('description', { name: integrationActionItem.name })}</Box>
    </Modal>
  )
}
