import { useEffect, useState } from 'react'

import { Box, Message, Modal, Text, useNotify } from '@cutover/react-ui'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { useBulkArchiveRunbooks } from 'main/services/queries/use-bulk-archive-runbooks'

type ArchiveRunbooksModalProps = {
  selectedRunbookIds: number[]
  templateType: string | undefined
  onSuccess?: () => void
  onClose: () => void
}

export const ArchiveRunbooksModal = ({
  selectedRunbookIds,
  templateType,
  onSuccess,
  onClose
}: ArchiveRunbooksModalProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'archiveRunbookModal' })
  const { mutate, isLoading, reset } = useBulkArchiveRunbooks(selectedRunbookIds)
  const notify = useNotify()
  const context =
    !templateType || templateType === 'off' ? 'runbook' : templateType === 'default' ? 'template' : 'snippet'
  const { listen } = useUserWebsocket()
  const [serverErrors, setServerErrors] = useState<string[]>([])

  useEffect(() => {
    listen(data => handleBulkArchiveResponse(data))
  }, [])

  const handleClose = () => {
    onClose()
    reset()
    setServerErrors([])
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleBulkArchiveResponse = (data: any) => {
    if (data?.meta?.headers?.request_method === 'runbooks_bulk_archive') {
      setIsSubmitting(false)
      if (data?.errors && data.errors.length) {
        // display error at the top of the modal
        setServerErrors(data.errors)
      } else {
        handleClose()
        onSuccess?.()
        notify.success(data.messages[0])
      }
    }
  }

  const handleArchiveRunbooks = () => {
    if (!isLoading && !isSubmitting) {
      setIsSubmitting(true)
    }
    mutate({ runbook_ids: selectedRunbookIds })
  }

  return (
    <Modal
      title={t('archive', { count: selectedRunbookIds.length, context: context })}
      open
      confirmText={t('confirm')}
      confirmIcon="archive"
      onClose={handleClose}
      onClickConfirm={isLoading || isSubmitting ? undefined : handleArchiveRunbooks}
      loading={isLoading || isSubmitting}
      loadingText={t('loading')}
    >
      <Box gap="small" direction="column">
        <Message type="error" message={serverErrors} />
        <Text data-testid="archive-runbooks-warning">
          {t('warning', { count: selectedRunbookIds.length, context: context })}
        </Text>
      </Box>
    </Modal>
  )
}
