import { useMemo } from 'react'

import { SelectedDates } from './types'

type UseFilterSelectedProps<T extends { start_display: number | null; end_display: number | null }> = {
  items: T[]
  dates?: SelectedDates
}

export function useFilterSelected<T extends { start_display: number | null; end_display: number | null }>({
  items,
  dates
}: UseFilterSelectedProps<T>) {
  return useMemo(() => {
    // With no selected dates -> return undefined. Happens if date object start/end are null or dates is undefined.
    // This is to differentiate the hook return value between zero results in the range (empty collection of T)
    // vs no range thus none selected (undefined)
    if (dates === undefined || dates.start === null) {
      return
    }

    return (
      items.filter(rb => {
        const start = rb.start_display
        // make both start and end present but with a duration of zero if start but no end
        // for runbooks, this happens if the runbook does not yet have tasks
        const end = rb.end_display ?? rb.start_display

        // Note: needed the below since start_display can be null
        if (!start || !end) return false

        return start <= dates.end && end >= dates.start
      }) || []
    )
  }, [dates, items])
}
