import { Box, Modal, SanitizedString } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'

export type DeleteUserModalProps = {
  closeModal: () => void
  isOpen: boolean
  loading: boolean
  onDelete: () => void
  userName?: string
}

export function DeleteUserModal({ isOpen, userName, closeModal, onDelete, loading }: DeleteUserModalProps) {
  const { t } = useLanguage()

  return (
    <Modal
      title={t('common:deleteUserButton')}
      open={isOpen}
      onClose={closeModal}
      confirmText={t('common:deleteButton')}
      confirmIcon="delete"
      onClickConfirm={onDelete}
      loading={loading}
      loadingText={t('common:deletingButton')}
    >
      <Box>
        <SanitizedString
          input={t('runbook:peoplePanel:users:deleteUserConfirmation', {
            userName: userName
          })}
        />
      </Box>
    </Modal>
  )
}
