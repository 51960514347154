import { useLanguage } from 'main/services/hooks'
import { FormModal } from 'main/components/shared/form'
import { ActiveRunbookModel, ActiveRunbookVersionModel } from 'main/data-access'
import { useMarkAsApproved } from 'main/services/queries/use-runbook-versions'
import { RunbookVersionMarkAsApprovedResponse } from 'main/services/api/data-providers/runbook-types'

type MarkAsApprovedModalProps = {
  onClose?: () => void
}

export const MarkAsApprovedModal = ({ onClose }: MarkAsApprovedModalProps) => {
  const { t } = useLanguage('runbook')
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const { mutateAsync: markAsApproved, isLoading } = useMarkAsApproved(runbookId, runbookVersionId)
  const processMarkAsApproved = ActiveRunbookVersionModel.useOnAction('mark_as_approved')

  const handleSubmit = async () => {
    return await markAsApproved()
  }

  const handleSuccess = (response: RunbookVersionMarkAsApprovedResponse) => {
    processMarkAsApproved(response)
  }

  return (
    <FormModal
      open
      formElementWrapper={false}
      title={t('markAsApprovedModal.title')}
      description={t('markAsApprovedModal.description')}
      confirmText={t('markAsApprovedModal.confirmText')}
      loadingText={t('markAsApprovedModal.confirmingText')}
      confirmIcon="like"
      loading={isLoading}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      onClose={onClose}
    />
  )
}
