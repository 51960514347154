import { useEffect } from 'react'
import { useTimeout } from 'react-use'

export const useForceRerenderOnTimeout = (delay: number | undefined = 0) => {
  const [_, cancelTimeout] = useTimeout(delay)

  useEffect(() => {
    if (!delay) cancelTimeout()
    return cancelTimeout
  }, [delay])
}
