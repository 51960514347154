import { CancelApprovalModal } from './cancel-approval-modal'
import { RequestApprovalModal } from './request-approval-modal'
import { MarkAsApprovedModal } from './mark-as-approved-modal'
import { SubmitReviewModal } from './submit-review-modal'
import { useModalClose, useModalValue } from 'main/data-access/hooks'
import { ViewReviewModal } from './view-review-modal'

export const TemplateWorkflowModals = () => {
  const activeModal = useModalValue()
  const modalClose = useModalClose()
  switch (activeModal?.active?.type) {
    case 'request-approval':
      return <RequestApprovalModal onClose={modalClose} />
    case 'request-reapproval':
      return <RequestApprovalModal onClose={modalClose} reapproval />
    case 'cancel-approval':
      return <CancelApprovalModal onClose={modalClose} />
    case 'mark-as-approved':
      return <MarkAsApprovedModal onClose={modalClose} />
    case 'submit-review':
      return <SubmitReviewModal onClose={modalClose} />
    case 'view-review':
      return <ViewReviewModal onClose={modalClose} />
    default:
      return null
  }
}
