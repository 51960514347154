import styled from 'styled-components/macro'

import { Link } from './link'
import { Image } from './image'
import { themeColor } from '../../../theme'
import { Message } from '../../../message'
import { Box } from '../../../layout'

export const Element = ({ attributes, children, element }: any) => {
  const style = { textAlign: element.align }

  switch (element.type) {
    case 'bulleted-list':
      return <StyledUl {...attributes}>{children}</StyledUl>
    case 'numbered-list':
      return <StyledOl {...attributes}>{children}</StyledOl>
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'list-item-text':
      return (
        <p style={{ ...style, lineHeight: '22px', marginTop: '0', marginBottom: '0' }} {...attributes}>
          {children}
        </p>
      )
    case 'quote':
      return (
        <StyledQuote style={style} {...attributes}>
          {children}
        </StyledQuote>
      )
    case 'code':
      return (
        <pre>
          <StyledCodeBlock {...attributes}>{children}</StyledCodeBlock>
        </pre>
      )
    case 'heading':
      return (
        <StyledHeading style={style} {...attributes}>
          {children}
        </StyledHeading>
      )

    case 'message':
      return (
        <Box margin={{ vertical: '16px' }} {...attributes}>
          <Message children={children} type={element.messageType} />
        </Box>
      )

    case 'image':
      return <Image attributes={attributes} children={children} element={element} />
    case 'link':
      return <Link attributes={attributes} children={children} element={element} />
    case 'table':
      return (
        <table
          style={{ ...style, width: '100%', marginTop: '16px', marginBottom: '16px', borderCollapse: 'collapse' }}
          {...attributes}
        >
          {children}
        </table>
      )
    case 'table-header':
      return <thead>{children}</thead>
    case 'table-header-cell':
      return <StyledTableHeader {...attributes}>{children}</StyledTableHeader>
    case 'table-body':
      return <tbody {...attributes}>{children}</tbody>
    case 'table-row':
      return <tr {...attributes}>{children}</tr>
    case 'table-cell':
      return <StyledTableCell {...attributes}>{children}</StyledTableCell>
    default:
      return (
        <p style={{ ...style, lineHeight: '22px' }} {...attributes}>
          {children}
        </p>
      )
  }
}

// Note we need !important to override angular global styles
const StyledHeading = styled.h4`
  margin: 16px 0 !important;
  line-height: 22px !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  span {
    color: ${themeColor('text')} !important;
  }
`

const StyledOl = styled.ol`
  padding-left: 16px;
  line-height: 22px;
  margin: 16px 0 16px 16px !important;
  list-style-type: number !important;
  ul,
  ol {
    margin: 0 0 0 16px !important;
    list-style-type: number !important;
  }
`

const StyledUl = styled.ul`
  padding-left: 16px;
  line-height: 22px;
  margin: 16px 0 16px 16px !important;
  list-style-type: disc !important;
  ul,
  ol {
    margin: 0 0 0 16px !important;
    list-style-type: circle !important;
  }
`

const StyledQuote = styled.blockquote`
  padding-left: 16px;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  line-height: 22px;
  border-left: 4px solid ${themeColor('bg-2')};
  color: ${themeColor('text-light')};
`

const StyledCodeBlock = styled.code`
  padding: 16px;
  border-radius: 8px;
  display: block;
  line-height: 20px;
  color: ${themeColor('text-light')};
  background-color: ${themeColor('bg-1-alpha')};
`

const StyledTableHeader = styled.th`
  padding: 8px;
  text-align: left;
  line-height: 22px;
  border-collapse: collapse;
  border: 1px solid ${themeColor('bg-4')};
  background-color: ${themeColor('bg-1-alpha')};
  min-width: 40px;
`

const StyledTableCell = styled.td`
  padding: 8px;
  line-height: 22px;
  border-collapse: collapse;
  border: 1px solid ${themeColor('bg-4')};
  min-width: 40px;
`
