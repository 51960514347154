import { ForwardedRef, ReactNode } from 'react'
import { DirectionType } from 'grommet/utils'

import {
  AnimatedFormField,
  FieldBox,
  FieldInlineErrorText,
  FieldLabelSuffix,
  FieldLabelText,
  FieldLabelTextWrap,
  FormFieldBorder,
  FormFieldInlineErrorBox,
  FormFieldLabelBox,
  FormFieldLabelBoxProps,
  HelpText
} from './form-field-components'
import { Icon, IconName } from '../../../icon'
import { Box } from '../../../layout'
import { LinkButton } from '../../../button'
import { ColorProp } from '../../../theme'
import { FormFieldBaseProps } from '../../form'

type FormFieldProps = FormFieldBaseProps & {
  /** Only used to calc width of datepicker dropdown, should do another way */
  containerRef?: ForwardedRef<HTMLDivElement>
  children?: ReactNode
  clickable?: boolean
  endComponent?: ReactNode
  labelSize?: 'small' | 'medium'
  selectedItems?: ReactNode
  startIcon?: IconName
  truncate?: boolean // TODO think this does nothing
  labelProps?: FormFieldLabelBoxProps
  tabIndex?: number
  direction?: DirectionType // TODO: Doesn't make sense for this to live on FormField if it is only applicable to checkbox/radio
  tag?: string // TODO: doesn't make sense for this to live on FormField if it isn't applicable across form fields
  /** Render the field without a bottom border */
  plain?: boolean
  /** Use labelSuffix to add supporting text to the top right of the component, eg a duration diff for datepickers. It will only show when the field has a value */
  labelSuffix?: string
  /** Use in combination with labelSuffix to set its color */
  labelSuffixColor?: ColorProp
  onClickLabelSuffixButton?: () => void
  labelSuffixButtonText?: string
}

export const FormField = ({
  containerRef,
  children,
  clickable,
  disabled,
  endComponent,
  hasError,
  inlineError,
  label,
  labelSize,
  selectedItems,
  required,
  startIcon,
  helpText,
  truncate,
  labelProps = {},
  tabIndex = -1,
  direction,
  tag,
  plain,
  labelSuffix,
  labelSuffixColor,
  onClickLabelSuffixButton,
  labelSuffixButtonText
}: FormFieldProps) => {
  const hasIcon = !!startIcon

  const content = (
    <FieldBox truncate={truncate} hasIcon={hasIcon} gap="0" direction={direction}>
      {startIcon && (
        <Box margin={{ left: 'xxsmall' }} flex={false}>
          <Icon icon={startIcon} color={hasError ? 'error' : disabled ? 'text-disabled' : 'text-light'} aria-hidden />
        </Box>
      )}
      <FieldLabelTextWrap>
        <FieldLabelText
          size="small"
          hasError={hasError}
          disabled={disabled}
          hasIcon={hasIcon}
          required={required}
          truncate
        >
          {label}
        </FieldLabelText>
        {labelSize === 'small' && (labelSuffix || !!onClickLabelSuffixButton) && (
          <FieldLabelSuffix
            color={labelSuffixColor ?? 'text-disabled'}
            size="small"
            data-testid={`${label}-title-suffix`}
          >
            {labelSuffix && labelSuffix}
            {!!onClickLabelSuffixButton && !disabled && (
              <LinkButton
                size="small"
                onClick={onClickLabelSuffixButton}
                label={labelSuffixButtonText}
                css="margin-left: 6px"
              />
            )}
          </FieldLabelSuffix>
        )}
      </FieldLabelTextWrap>
      {children}
      {(endComponent || helpText) && (
        <Box gap="xxsmall" margin={{ right: 'xxsmall', left: 'xxsmall' }} flex={false} direction="row" align="center">
          {endComponent}
          {helpText && <HelpText text={helpText} />}
        </Box>
      )}
    </FieldBox>
  )

  return (
    <AnimatedFormField
      ref={containerRef}
      labelSize={labelSize ?? 'small'}
      hasIcon={hasIcon}
      hasTooltipText={!!helpText}
    >
      <FormFieldLabelBox
        {...labelProps}
        clickable={clickable}
        hasIcon={hasIcon}
        hasError={hasError}
        disabled={disabled}
        width="100%"
        pad={{ top: '13px', bottom: inlineError ? '0' : '17.5px' }}
        tag={tag}
        flex={false}
        tabIndex={tabIndex}
        gap="0"
      >
        {selectedItems}
        {plain ? (
          content
        ) : (
          <FormFieldBorder width="100%" disabled={disabled} hasError={hasError} flex={false}>
            {content}
          </FormFieldBorder>
        )}
        {inlineError && (
          <FormFieldInlineErrorBox height={{ min: '17.5px' }}>
            {/* NOTE: This would be 3px from the designs but it is too crowded with the adjacent field labels */}
            <FieldInlineErrorText css="position: relative; top: 1px;">{inlineError}</FieldInlineErrorText>
          </FormFieldInlineErrorBox>
        )}
      </FormFieldLabelBox>
    </AnimatedFormField>
  )
}
