import * as yup from 'yup'
import { useFormContext } from 'react-hook-form'

import { useLanguage } from 'main/services/hooks'
import { FormModal, RadioboxGroupField, TextAreaField } from 'main/components/shared/form'
import { ActiveRunbookModel, ActiveRunbookVersionModel } from 'main/data-access'
import { ReviewPayload, useSubmitReview } from 'main/services/queries/use-runbook-versions'
import { RunbookVersionSubmitReviewResponse } from 'main/services/api/data-providers/runbook-types'

type SubmitReviewModalProps = {
  onClose?: () => void
}

const validationSchema = yup.object({
  decision: yup.string().oneOf(['approved', 'rejected']).required(),
  comment: yup.string().when(['decision'], {
    is: (decision: 'approved' | 'rejected') => decision === 'rejected',
    then: schema => schema.required(),
    otherwise: schema => schema.notRequired()
  })
})

export type SubmitReviewFormType = yup.InferType<typeof validationSchema>

export const SubmitReviewModal = ({ onClose }: SubmitReviewModalProps) => {
  const { t } = useLanguage('runbook')
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const { mutateAsync: submitReview, isLoading } = useSubmitReview(runbookId, runbookVersionId)
  const processSubmitReview = ActiveRunbookVersionModel.useOnAction('submit_review')

  const handleSubmit = async (data: ReviewPayload) => {
    return await submitReview(data)
  }

  const handleSuccess = (response: RunbookVersionSubmitReviewResponse) => {
    processSubmitReview(response)
  }

  const defaultValues = {
    decision: 'approved' as 'approved' | 'rejected',
    comment: undefined
  }

  return (
    <FormModal<SubmitReviewFormType>
      open
      title={t('submitReviewModal.title')}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      onClose={onClose}
      confirmText={t('submitReviewModal.confirmText')}
      loadingText={t('submitReviewModal.confirmingText')}
      loading={isLoading}
      schema={validationSchema}
      defaultValues={defaultValues}
    >
      <FormContent />
    </FormModal>
  )
}

const FormContent = () => {
  const { t } = useLanguage('runbook')
  const { watch } = useFormContext<SubmitReviewFormType>()
  const decision = watch('decision')

  return (
    <>
      <RadioboxGroupField<SubmitReviewFormType>
        name="decision"
        label={t('submitReviewModal.fields.decision.label')}
        direction="row"
        options={[
          { value: 'approved', label: t('submitReviewModal.fields.decision.options.approve') },
          { value: 'rejected', label: t('submitReviewModal.fields.decision.options.reject') }
        ]}
      />
      <TextAreaField<SubmitReviewFormType>
        required={decision === 'rejected'}
        label={t('submitReviewModal.fields.comment.label')}
        name="comment"
      />
    </>
  )
}
